import OriginContainer from './OriginContainer';
import Icon from './Icon';

import { engine, GRID, MARGIN } from './global';
import utils from '../engine/utils';

const makeLabel = (name, quantity) => {
    return name + (quantity > 1 ? ' (' + utils.number.format(quantity) + ')' : '');
}

export default class Tooltip extends OriginContainer {
    constructor(scene, item, quantity) {
        super(scene);
        this.item = item;
        this.quantity = quantity;
        this.setDepth(100);
    }

    create = () => {
        let y = MARGIN;

        // background
        this.background = this.scene.add.rectangle(0, 0, 0, 0, 0x333333);
        this.background.setOrigin(0, 0);
        this.background.setStrokeStyle(1, 0xffffff, 1);
        this.container.add(this.background);

        // label
        this.name = engine.getType(this.item)?.name;
        this.label = this.scene.add.text(MARGIN, y, makeLabel(this.name, this.quantity), { font: '12pt Calibri', fill: '#ffffff' });
        this.label.setStroke(0x000000, 3);
        this.container.add(this.label);
        y += this.label.height;

        // description
        const description = engine.getType(this.item)?.description;
        if (description) {
            this.description = this.scene.add.text(MARGIN, y, description, { font: '12pt Calibri', fill: '#ffffff' });
            this.description.setStroke(0x000000, 3);
            this.container.add(this.description);
            y += this.description.height;
        }

        y += MARGIN;

        // recipes
        const recipes = engine.recipes.for(this.item);
        if (recipes?.length) {
            this.recipe = this.scene.add.text(MARGIN, y, 'Recipe', { font: '12pt Calibri', fill: '#ffffff' });
            this.recipe.setStroke(0x000000, 3);
            this.container.add(this.recipe);
            y += this.recipe.height;
        }

        let arrowWidth = 1;
        for (let recipe of recipes) {
            let x = MARGIN;

            // inputs
            for (let item of Object.keys(recipe.input)) {
                const icon = new Icon(this.scene, item, recipe.input[item], true);
                icon.setPosition(x, y);
                this.container.add(icon);

                x += icon.width + MARGIN;
            }

            // arrow
            {
                const text = this.scene.add.text(x, y + GRID / 2, ' \u25B6 ', { font: '10pt Calibri', fill: '#ffffff' });
                text.setStroke(0x000000, 3);
                text.setOrigin(0, .5);
                this.container.add(text);

                arrowWidth = text.width + MARGIN;
                x += arrowWidth;
            }

            // object
            if (recipe.with) {
                const icon = new Icon(this.scene, recipe.with, null, true);
                icon.setPosition(x, y);
                this.container.add(icon);

                x += icon.width + MARGIN;
            }

            // arrow
            if (recipe.with) {
                const text = this.scene.add.text(x, y + GRID / 2, ' \u25B6 ', { font: '10pt Calibri', fill: '#ffffff' });
                text.setStroke(0x000000, 3);
                text.setOrigin(0, .5);
                this.container.add(text);

                x += text.width + MARGIN;
            }

            // timer
            if (recipe.time) {
                const text = this.scene.add.text(x - arrowWidth, y + GRID, utils.number.time(recipe.time), { font: '10pt Calibri', fill: '#ffffff' });
                text.setStroke(0x000000, 3);
                text.setOrigin(0, .5);
                this.container.add(text);
            }

            // outputs
            for (let item of Object.keys(recipe.output)) {
                const icon = new Icon(this.scene, item, recipe.output[item], true);
                icon.setPosition(x, y);
                this.container.add(icon);

                x += icon.width + MARGIN;
            }

            y += GRID + MARGIN;
        }

        this.resize();
    }

    setQuantity = (quantity) => {
        this.quantity = quantity;
        if (this.label) {
            this.label.setText(makeLabel(this.name, this.quantity));
            this.resize();
        }
    }

    resize = () => {
        const children = this.container.list.filter(o => o !== this.background);
        const width = Math.max(...children.map(o => o.x + o.width));
        const height = Math.max(...children.map(o => o.y + o.height));
        this.background.setSize(width + MARGIN, height + MARGIN);
        this.setSize(this.background.width, this.background.height);
    }

    hide = () => {
        this.visible = false;
    }

    show = () => {
        if (!this.label) this.create();
        this.visible = true;
    }
}