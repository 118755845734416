import ShortUniqueId from 'short-unique-id';
const suid = new ShortUniqueId({ length: 10 });

const utils = {
    progress: {
        percent: (start, now, end) => {
            if (now >= end) {
                return 100;
            } else if (now > start) {
                return 100 * (now - start) / (end - start);
            } else {
                return 0;
            }
        },
        time: (start, percent, end) => {
            const duration = percent * (end - start) / 100;
            return start + duration;
        }
    },
    frequency: {
        choose: (array, dist) => {
            if (!array || !array.length) return null;

            if (!array[0].max) {
                // prep our buckets
                for (let i = 0; i < array.length; i++) {
                    array[i].min = i > 0 ? array[i - 1].max : 0;
                    array[i].max = array[i].min + array[i].frequency;
                }
            }

            const bucket = dist * array[array.length - 1].max;
            return array.find(e => bucket < e.max);
        }
    },
    number: {
        format: (num) => {
            return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
        },
        compact: (num) => {
            return num.toLocaleString(undefined, { notation: 'compact', maximumFractionDigits: 0 });
        },
        time: (num) => {
            if (num < 1000) return utils.number.format(num) + 'ms';
            if (num < 60000) return utils.number.format(num / 1000) + 's';
            return utils.number.format(num / 60000) + 'm';
        }
    },
    uid: () => {
        // wrapper to generate a UID
        return suid.randomUUID();
    }
};

export default utils;