import Phaser from 'phaser';

// gives an origin to the container class
// subclasses should add children to this.container
export default class OriginContainer extends Phaser.GameObjects.Container {
    constructor(scene, x, y, children) {
        super(scene, x, y);

        this.container = scene.add.container();
        if (children) this.container.add(children);
        this.add(this.container);

        this.containerX = 0;
        this.containerY = 0;
    }

    setOrigin = (x, y) => {
        this.containerX = x;
        this.containerY = y;
        this.fix();
    }

    setSize = (w, h) => {
        super.setSize(w, h);
        this.fix();
    }

    setWidth = (w) => {
        super.setWidth(w);
        this.fix();
    }

    setHeight = (h) => {
        super.setHeight(h);
        this.fix();
    }

    fix = () => {
        this.container.setPosition(-this.containerX * this.width, -this.containerY * this.height);
    }
}