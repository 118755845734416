import Button from './Button';
import OriginContainer from './OriginContainer';

import { engine, MARGIN, GRID } from './global';
import Inventory from './Inventory';
import RecipePicker from './RecipePicker';
import Program from './Program';
import utils from '../engine/utils';

const WIDTH = 600;

export default class Hotbar extends OriginContainer {
    constructor(scene) {
        super(scene);

        engine.on('load', this.onLoad);
        engine.on('update', this.onUpdate);
        engine.on('remove', this.onRemove);
        engine.on('select', this.select);

        this.update();
    }

    destroy = () => {
        engine.off('load', this.onLoad);
        engine.off('update', this.onUpdate);
        engine.off('remove', this.onRemove);
        engine.off('select', this.select);

        super.destroy();
    }

    select = (object) => {
        this.selected = object;
        this.update();
    }

    onLoad = () => {
        this.selected = null;
        this.update();
    }

    onUpdate = (object) => {
        if (object.id !== this.selected?.id) return;
        this.selected = object;
        this.update();
    }

    onRemove = (object) => {
        if (object.id !== this.selected?.id) return;
        this.selected = null;
        this.update();
    }

    updateCoordinates = () => {
        const camera = this.scene.scene.get('World').cameras.main;
        const view = camera.worldView;
        if (camera.zoom === this.zoom && view.centerX === this.centerX && view.centerY === this.centerY) return;

        this.zoom = camera.zoom;
        this.centerX = view.centerX;
        this.centerY = view.centerY;
        this.update();
    }

    update = () => {
        const object = this.selected || { x: Math.floor(this.centerX / GRID), y: Math.floor(this.centerY / GRID), zoom: Math.floor(this.zoom * 100) };
        const type = object.type ? engine.getObjectType(object.type) : {};

        // build from the top-down
        let currentY = 0;

        // locked robot
        if (!this.notplayer) {
            this.notplayer = this.scene.add.text(0, 0, 'Complete a delivery to gain access to this bot', { font: '12pt Calibri', fill: '#ffffff' });
            this.notplayer.setStroke(0x000000, 3);
            this.container.add(this.notplayer);
        }
        this.notplayer.visible = type.target?.program && !object.player;
        if (this.notplayer.visible) {
            this.notplayer.setPosition(0, currentY);
            currentY += this.notplayer.height;
        }

        // recipe picker
        if (!this.recipe) {
            this.recipe = new RecipePicker(this.scene);
            this.container.add(this.recipe);
        }
        this.recipe.visible = type.target?.program === 'recipepicker' && object.player;
        if (this.recipe.visible) {
            this.recipe.setPosition(0, currentY);
            this.recipe.update(object);
            currentY += this.recipe.height;
        }

        // programming
        if (!this.program) {
            this.program = new Program(this.scene);
            this.container.add(this.program);
        }
        this.program.visible = type.target?.program === 'actionlist' && object.player;
        if (this.program.visible) {
            this.program.setPosition(0, currentY);
            this.program.update(object);
            currentY += this.program.height;
        }

        // delivery area
        if (!this.delivery) {
            this.delivery = new Inventory(this.scene, 0, 0, 'Delivery Required');
            this.container.add(this.delivery);
        }
        this.delivery.visible = object?.action?.input && object?.action?.id === 'deplete';
        if (this.delivery.visible) {
            this.delivery.setStatus('Completed: ' + (object.depletions || 0));
            this.delivery.setPosition(0, currentY);
            this.delivery.update(object.action.input);
            currentY += this.delivery.height;
        }

        // inventory area
        if (!this.inventory) {
            this.inventory = new Inventory(this.scene, 0, 0, 'Inventory');
            this.container.add(this.inventory);
        }
        this.inventory.visible = Boolean(object.inventory);
        if (this.inventory.visible) {
            this.inventory.setPosition(0, currentY);
            this.inventory.update(object.inventory);
            currentY += this.inventory.height;
        }

        // nameplate area
        if (!this.name) {
            this.name = this.scene.add.text(0, 0, '', { font: '18pt Calibri', fill: '#ffffff' });
            this.name.setStroke(0x000000, 3);
            this.container.add(this.name);
        }
        this.name.setText(object.name ? object.name + ' (' + type.name + ')' : type.name);
        this.name.setPosition(MARGIN, currentY + MARGIN);

        // menu buttons (right align)
        if (!this.menu) {
            this.menu = new Button(this.scene, 0, 0, 'M');
            this.menu.setOrigin(1, 0);
            this.menu.on('pointerup', this.scene.clickMenu);
            this.container.add(this.menu);
        }
        this.menu.setPosition(WIDTH, currentY);

        if (!this.recipes) {
            this.recipes = new Button(this.scene, 0, 0, 'R');
            this.recipes.setOrigin(1, 0);
            this.recipes.on('pointerup', this.scene.clickRecipes);
            this.container.add(this.recipes);
        }
        this.recipes.setPosition(this.menu.x - this.menu.width, currentY);

        // coords
        if (!this.coords) {
            this.coords = this.scene.add.text(0, 0, '', { font: '18pt Calibri', fill: '#ffffff' });
            this.coords.setOrigin(1, 0);
            this.coords.setStroke(0x000000, 3);
            this.container.add(this.coords);
        }
        this.coords.setText('x:' + utils.number.format(object.x) + ', y:' + utils.number.format(object.y)); // + (object.zoom ? ' @ ' + object.zoom + '%' : '')
        this.coords.setPosition(this.recipes.x - this.recipes.width - MARGIN, currentY + MARGIN);

        // overall size
        if (!this.background) {
            this.background = this.scene.add.rectangle(0, 0, 0, 0, 0x333333);
            this.background.setOrigin(0, 0);
            this.container.addAt(this.background, 0);
        }
        this.background.setSize(WIDTH, this.name.y + this.name.height + MARGIN);
        this.background.setInteractive();

        this.setSize(this.background.width, this.background.height);
    }
}