import Phaser from 'phaser';

const PADDING = 2;
const FONTSIZE = 16;

export default class Message extends Phaser.GameObjects.Container {
    constructor(scene) {
        super(scene);

        this.rectangle = this.scene.add.rectangle(0, 0, 1, 1, 0xffffff);
        this.rectangle.setOrigin(0, 0);
        this.add(this.rectangle);

        this.caret = this.scene.add.rectangle(0, 4, 6, 6, 0xffffff);
        this.caret.setAngle(45);
        this.caret.setOrigin(0, 0);
        this.add(this.caret);

        this.text = this.scene.add.text(0, 0, '', { fontFamily: 'Calibri', fill: '#000000', wordWrap: { width: 200, useAdvancedWrap: true }});
        this.text.setOrigin(0, 0);
        this.text.setPosition(PADDING, PADDING);
        this.add(this.text);

        this.scene.cameras.main.on('camerazoomcomplete', this.zoom);
    }

    setText = (text) => {
        this.text.setText(text);
        this.resize();
    }

    zoom = () => {
        if (this.visible) this.resize();
    }

    resize = () => {
        const zoom = this.scene.cameras.main.zoom;

        // increase font smoothness as we zoom in
        this.text.setFontSize(FONTSIZE * zoom);
        this.text.setScale(1 / zoom);
        this.text.setWordWrapWidth(200 * zoom, true);

        this.rectangle.setSize(this.text.displayWidth + 2 * PADDING, this.text.displayHeight + 2 * PADDING);
    }

    destroy = () => {
        this.scene.cameras.main.off('camerazoomcomplete', this.zoom);
        super.destroy();
    }
}