import Phaser from 'phaser';

import Progress from './Progress';
import Message from './Message';

import sprites from './sprites';
import { GRID, PROGRESS_BAR, COUNTDOWN_BAR, engine, wasDragged } from './global';

export default class Sprite extends Phaser.GameObjects.Container {
    constructor(scene) {
        super(scene);

        // prep sets
        this.PROGRESS_BAR = new Set(PROGRESS_BAR);
        this.COUNTDOWN_BAR = new Set(COUNTDOWN_BAR);
        COUNTDOWN_BAR.forEach(e => this.PROGRESS_BAR.add(e));

        engine.on('select', this.onSelect);
        engine.on('debug', this.onDebug);
    }

    onChange = (object) => {
        this.object = object;
        this.update();
    }

    onSelect = (object) => {
        const before = this.select.visible;
        const after = object != null && object.id === this.object.id;

        this.select.visible = after;
        if (before !== after) this.hitbox();
    }

    onDebug = (debug) => {
        if (debug) {
            this.scene.input.enableDebug(this.image);
        } else {
            this.scene.input.removeDebug(this.image);
        }
    }

    click = (pointer) => {
        if (!wasDragged(pointer)) {
            engine.listeners.select.forEach(listener => listener(this.object));
        }
    }

    sprite = () => {
        const object = this.object;
        const sprite = sprites[object.type](object);

        // merge info from underlying atlas
        if (sprite.atlas) {
            const data = this.scene.textures.get(sprite.atlas).get(Array.isArray(sprite.frame) ? sprite.frame[0] : sprite.frame).customData;
            sprite.width = data.frame.w;
            sprite.height = data.frame.h;
            sprite.origin = data.origin;
            sprite.hitbox = { x: data.hitbox.x, y: data.hitbox.y, width: data.hitbox.w, height: data.hitbox.h };
        }

        return sprite;
    }

    hitbox = () => {
        const sprite = this.sprite();

        if (this.select.visible) {
            // use a small hitbox, so that objects behind it can be clicked
            this.image.input.hitArea.setTo(sprite.origin.x, sprite.origin.y, this.width, this.height);
        } else {
            // normal size hitbox
            this.image.input.hitArea.setTo(sprite.hitbox.x, sprite.hitbox.y, sprite.hitbox.width, sprite.hitbox.height);
        }

        if (localStorage.debug) {
            // need to reset the debug for size changes
            this.scene.input.removeDebug(this.image);
            this.scene.input.enableDebug(this.image);
        }
    }

    update = () => {
        const object = this.object;
        const sprite = this.sprite();
        const type = engine.getObjectType(object.type);

        this.setPosition(GRID * object.x, GRID * object.y);
        this.setSize(GRID * type.width, GRID * type.height);
        this.setDepth(object.y);

        // setup highlight box
        // TODO Make this an arrow indicator instead?
        if (!this.highlight) {
            this.highlight = this.scene.add.rectangle(0, 0, this.width, this.height, 0x0000ff, .3);
            this.highlight.setStrokeStyle(1, 0x0000ff, 1);
            this.highlight.setOrigin(0, 0);
            this.highlight.visible = false;
            this.add(this.highlight);
        }

        this.highlight.setSize(this.width, this.height);
        this.highlight.visible = object.highlight;

        // setup selection box
        if (!this.select) {
            this.select = this.scene.add.rectangle(0, 0, this.width, this.height, 0x000000, .5);
            this.select.setStrokeStyle(1, 0xffffff, 1);
            this.select.setOrigin(0, 0);
            this.select.visible = false;
            this.add(this.select);
        }

        this.select.setSize(this.width, this.height);

        // setup error box
        if (!this.error) {
            this.error = this.scene.add.rectangle(0, 0, this.width, this.height, 0xff0000, .3);
            this.error.setStrokeStyle(1, 0xff0000, 1);
            this.error.setOrigin(0, 0);
            this.error.visible = false;
            this.add(this.error);
        }

        this.error.setSize(this.width, this.height);
        this.error.visible = object.action && object.action.error;

        // TODO Better graphics when selected + error are both on

        // setup texture
        if (!this.image) {
            this.image = this.scene.add.sprite(-sprite.origin.x, -sprite.origin.y, sprite.atlas, Array.isArray(sprite.frame) ? sprite.frame[0] : sprite.frame);
            this.image.setOrigin(0, 0);
            this.image.setInteractive(new Phaser.Geom.Rectangle(), Phaser.Geom.Rectangle.Contains);
            this.image.on('pointerup', this.click);
            this.add(this.image);
        }

        // set the animation or frame
        if (Array.isArray(sprite.frame)) {
            if (!this.scene.anims.exists(sprite.frame[0])) {
                this.scene.anims.create({
                    key: sprite.frame[0],
                    frames: sprite.frame.map(frame => ({ key: sprite.atlas, frame })),
                    frameRate: 10,
                    repeat: -1
                });
            }
            this.image.play(sprite.frame[0], true);
        } else {
            this.image.stop();
            this.image.setFrame(sprite.frame);
        }

        this.image.displayWidth = sprite.width;
        this.image.displayHeight = sprite.height;
        this.image.setPosition(-sprite.origin.x, -sprite.origin.y);
        this.image.setAlpha(object.ghost ? .25 : 1);

        this.hitbox();

        // setup progress bar
        if (!this.progress) {
            this.progress = new Progress(this.scene);
            this.scene.add.existing(this.progress);
            this.add(this.progress);
        }

        if (object.action && object.action.start && this.PROGRESS_BAR.has(object.action.id)) {
            this.progress.maxWidth = this.width;
            this.progress.setPosition(0, this.height);
            this.progress.setProgress(object.action.start, object.action.end, this.COUNTDOWN_BAR.has(object.action.id));
        } else {
            this.progress.clearProgress();
        }

        // setup message bubble
        if (!this.message) {
            this.message = new Message(this.scene);
            this.message.visible = false;
            this.message.setDepth(999999999);
            this.scene.add.existing(this.message);
        }

        const message = object.message?.text || object.action?.error?.friendly;
        if (message) {
            // position in the world, for proper depth
            const matrix = this.getWorldTransformMatrix();

            this.message.setText(message);
            this.message.setPosition(matrix.getX(this.width, 0), matrix.getY(this.width, 0));
            this.message.visible = true;
        } else {
            this.message.visible = false;
        }

        // setup name
        if (!this.name) {
            this.name = this.scene.add.text(0, 0, '', { fontFamily: 'Calibri', fill: '#ffffff' });
            this.name.setStroke(0x000000, 3);
            this.name.setOrigin(.5, 1);
            this.add(this.name);
        }

        if (object.name) {
            this.name.setText(object.name);
            this.name.setPosition(this.width / 2, sprite.hitbox.y - sprite.origin.y);
            this.name.visible = true;
        } else {
            this.name.visible = false;
        }
    }

    destroy = () => {
        this.scene.input.removeDebug(this.image);
        if (this.message) this.message.destroy(); // not a child due to z-index
        window.clearTimeout(this.interval);

        engine.off('select', this.onSelect);
        engine.off('debug', this.onDebug);

        super.destroy();
    }
}