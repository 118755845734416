import OriginContainer from './OriginContainer';
import CodeLine from './CodeLine';

export default class Code extends OriginContainer {
    constructor(scene, x, y) {
        super(scene, x, y);
        this.lines = [];
        this.lineHeight = new CodeLine(this.scene, 0, 0).height;
    }

    update = (object, mode) => {
        this.lines.forEach(line => line.visible = false);

        const lines = object.program?.code || [];
        for (let i = 0; i < lines.length; i++) {
            if (!this.lines[i]) {
                this.lines[i] = new CodeLine(this.scene, 0, i * this.lineHeight);
                this.container.add(this.lines[i]);
            }

            this.lines[i].update(object, mode, i);
            this.lines[i].visible = true;
        }

        this.setSize(Math.max(...this.lines.map(line => line.width)), lines.length * (this.lineHeight || 0));
    }
}