import Phaser from 'phaser';

import art from '../generated/art.json';

export default class Splash extends Phaser.Scene {
    constructor() {
        super({ key: 'Splash' });
    }

    preload = () => {
        // setup loading screen
        this.line1 = this.add.text(0, 0, 'Shoogen', { font: '24pt Calibri', fill: '#ffffff' });
        this.line1.setOrigin(.5, .5);

        this.line2 = this.add.text(0, 0, 'proudly presents', { font: '18pt Calibri', fill: '#ffffff' });
        this.line2.setOrigin(.5, 0);

        this.progress = this.add.text(0, 0, 'Loading...', { font: '18pt Calibri', fill: '#ffffff' });
        this.progress.setOrigin(.5, 1);

        this.events.on('shutdown', this.shutdown);
        this.scale.on('resize', this.resize);
        this.resize();

        // load assets
        for (let asset of art) {
            if (asset.file.endsWith('.json')) continue;
            if (asset.atlas) {
                this.load.atlas(asset.file, require(`../generated/images/${asset.file}`), require(`../generated/images/${asset.atlas}`));
            } else {
                this.load.image(asset.file, require(`../generated/images/${asset.file}`));
            }
        }
        //this.load.audio('click', require('../sfx/click.ogg'));

        // percentage bar
        this.load.on('progress', this.percent);
    }

    create = () => {
        this.tweens.add({
            targets: [ this.line1, this.line2, this.progress ],
            alpha: 0,
            delay: 10,
            duration: 10,
            ease: 'Power2'
        }).on('complete', () => this.scene.start('MainMenu'));
    }

    shutdown = () => {
        this.scale.off('resize', this.resize);
    }

    percent = (value) => {
        this.progress.setText('Loading... ' + parseInt(value * 100) + '%');
    }

    resize = () => {
        const camera = this.cameras.main;
        const x = camera.width / 2;

        this.line1.setPosition(x, camera.height / 2);
        this.line2.setPosition(x, this.line1.y + this.line1.height);
        this.progress.setPosition(x, camera.height - 20);
    }
}