import OriginContainer from './OriginContainer';
import Button from './Button';
import ActionPanel from './ActionPanel';
import Code from './Code';

import { engine, MARGIN } from './global';
import CodeLine from './CodeLine';

export default class Program extends OriginContainer {
    constructor(scene, x, y) {
        super(scene, x, y);

        this.manual = new Button(this.scene, MARGIN, 0, 'Manual', { fontSize: 12, margin: 4 });
        this.manual.on('pointerup', () => this.onMode('manual'));
        this.container.add(this.manual);

        this.code = new Button(this.scene, this.manual.x + this.manual.width + MARGIN, 0, 'Code', { fontSize: 12, margin: 4 });
        this.code.on('pointerup', () => this.onMode('code'));
        this.container.add(this.code);

        this.run = new Button(this.scene, this.code.x + this.code.width + MARGIN, 0, 'Run', { fontSize: 12, margin: 4 });
        this.run.on('pointerup', () => this.onMode('run'));
        this.container.add(this.run);
    }

    update = (object) => {
        if (object?.id !== this.selected?.id) {
            // object changed, reset internal state
            this.mode = object.power ? 'run' : 'manual';
            this.adding = false;
            this.loading = false;
        } else if (object?.program?.code?.length !== this.codechange) {
            // program changed
            this.adding = false;
            this.loading = false;
        } else if (this.mode !== 'code') {
            // tab switched
            this.adding = false;
            this.loading = false;
        }
        this.selected = object;
        this.codechange = object?.program?.code?.length;

        let y = MARGIN;

        // action icons
        if (!this.panel) {
            this.panel = new ActionPanel(this.scene);
            this.container.add(this.panel);
        }
        this.panel.visible = this.mode === 'manual' || this.adding;
        if (this.panel.visible) {
            this.panel.setPosition(MARGIN, y);
            this.panel.update(object, this.mode);
            y += this.panel.height + MARGIN;
        }

        // current action
        if (!this.action) {
            this.action = new CodeLine(this.scene, 0, 0);
            this.container.add(this.action);
        }
        this.action.visible = this.mode === 'run';
        if (this.action.visible) {
            this.action.setPosition(MARGIN, y);
            this.action.update(object, this.mode, object.step);
            y += this.action.height + MARGIN;
        }

        // show the code
        if (!this.lines) {
            this.lines = new Code(this.scene, 0, 0);
            this.container.add(this.lines);
        }
        this.lines.visible = this.mode === 'code';
        if (this.lines.visible) {
            this.lines.setPosition(MARGIN, y);
            if (this.loading) {
                const programlist = {
                    id: object.id,
                    program: {
                        code: engine.getPrograms().map(program => ({ id: program.id }))
                    }
                };
                this.lines.update(programlist, 'load');
            } else {
                this.lines.update(object, this.mode);
            }
            y += this.lines.height;
        }

        // code buttons
        if (!this.plus) {
            this.plus = new Button(this.scene, 0, 0, ' + ', { fontSize: 12, margin: 1 });
            this.plus.on('pointerup', this.onAdd);
            this.container.add(this.plus);
        }
        if (!this.load) {
            this.load = new Button(this.scene, 0, 0, ' Load ', { fontSize: 12, margin: 1 });
            this.load.on('pointerup', this.onLoad);
            this.container.add(this.load);
        }
        if (!this.save) {
            this.save = new Button(this.scene, 0, 0, ' Save ', { fontSize: 12, margin: 1 });
            this.save.on('pointerup', this.onSave);
            this.container.add(this.save);
        }
        if (!this.clear) {
            this.clear = new Button(this.scene, 0, 0, ' Clear ', { fontSize: 12, margin: 1 });
            this.clear.on('pointerup', this.onClear);
            this.container.add(this.clear);
        }
        if (!this.cancel) {
            this.cancel = new Button(this.scene, 0, 0, ' Cancel ', { fontSize: 12, margin: 1 });
            this.cancel.on('pointerup', this.onCancel);
            this.container.add(this.cancel);
        }

        this.plus.visible = this.mode === 'code' && !this.loading;
        this.load.visible = this.mode === 'code' && !this.loading && engine.getPrograms().length;
        this.save.visible = this.mode === 'code' && !this.loading && object?.program?.code?.length;
        this.clear.visible = this.mode === 'code' && !this.loading && object?.program?.code?.length;
        this.cancel.visible = this.loading;

        let x = MARGIN;
        if (this.plus.visible) {
            this.plus.setPosition(x, y);
            x += this.plus.width + MARGIN;
        }
        if (this.load.visible) {
            this.load.setPosition(x, y);
            x += this.load.width + MARGIN;
        }
        if (this.save.visible) {
            this.save.setPosition(x, y);
            x += this.save.width + MARGIN;
        }
        if (this.clear.visible) {
            this.clear.setPosition(x, y);
            x += this.clear.width + MARGIN;
        }
        if (this.cancel.visible) {
            this.cancel.setPosition(x, y);
            x += this.cancel.width + MARGIN;
        }
        if (this.plus.visible || this.cancel.visible) {
            y += this.plus.height + MARGIN;
        }

        // tabs on bottom
        this.manual.setActive(this.mode === 'manual');
        this.manual.setY(y);

        this.code.setActive(this.mode === 'code');
        this.code.setY(y);

        this.run.setActive(this.mode === 'run');
        this.run.setY(y);

        y += this.manual.height;

        this.setSize(Math.max(this.lines.width, this.panel.width), y);
    }

    onMode = (mode) => {
        if (this.mode === mode) return;
        this.mode = mode;
        engine.updateObject(this.selected.id, { power: (mode === 'run'), action: undefined, done: undefined });
    }

    onAdd = () => {
        this.adding = !this.adding;
        this.loading = false;

        // trick to trigger an update
        engine.updateObject(this.selected.id, {});
    }

    onLoad = () => {
        this.adding = false;
        this.loading = true;

        // trick to trigger an update
        engine.updateObject(this.selected.id, {});
    }

    onCancel = () => {
        this.adding = false;
        this.loading = false;

        // trick to trigger an update
        engine.updateObject(this.selected.id, {});
    }

    onSave = () => {
        const id = window.prompt('Function name:');
        if (!id) return;
        if (engine.getProgram(id) && !window.confirm('This function name is already in use. Do you want to overwrite it?')) return;
        if (!/^[_a-z][_a-z0-9]*$/i.test(id)) {
            window.alert('Functions can only use letters or numbers, cannot contain spaces, and must start with a letter.');
            return this.onSave();
        }

        const program = { ...this.selected.program, id };
        engine.setProgram(program);

        // trick to trigger an update
        engine.updateObject(this.selected.id, {});
    }

    onClear = () => {
        engine.updateObject(this.selected.id, { step: undefined, program: { language: 'actionlist', code: [] } });
    }
}