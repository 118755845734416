import Phaser from 'phaser';

import Login from './canvas/Login';
import Splash from './canvas/Splash';
import MainMenu from './canvas/MainMenu';
import World from './canvas/World';
import HUD from './canvas/HUD';

import blizzard from './api/blizzard';

const config = {
    type: Phaser.AUTO,
    backgroundColor: '#000000',
    dom: {
        createContainer: true
    },
    scale: {
        mode: Phaser.Scale.RESIZE,
        parent: 'canvas'
    },
    scene: [ Login, Splash, World, HUD, MainMenu ]
};

window.artell ??= {};

if (blizzard.handleOauthRedirect()) {
    // do not render game
} else {
    window.artell.game = new Phaser.Game(config);
}