import Phaser from 'phaser';
import Button from './Button';

import { engine, MARGIN } from './global';
import test from '../test';

export default class MainMenu extends Phaser.Scene {
    constructor() {
        super({ key: 'MainMenu' });
        this.buttons = [];
    }

    create = () => {
        const note = `
A Note From Shoogen:

Thanks for playtesting! Please bear in mind the game is still under development,
and all the graphics and sounds are just placeholders. I am hoping you can see
the potential, and give feedback in the Discord to help make it as fun as possible.

I may have to periodically reset the map. Your saved progress may be lost, but
just ask if you want me to fix an old save file.
        `;

        this.logo = this.add.image(0, 0, 'atlas/logo.png', 'logo');
        this.logo.setOrigin(1, 1);

        this.text = this.add.text(0, 0, 'Artell Main Menu', { font: '30pt Calibri', fill: '#ffffff', align: 'center' });
        this.text.setOrigin(.5, 0);

        this.notes = this.add.text(0, 0, note, { font: '18pt Calibri', fill: '#ffffff', align: 'center' });
        this.notes.setOrigin(.5, 0);

        // discord
        this.discord = new Button(this, 0, 0, 'Join Discord');
        this.discord.setOrigin(1, 1);
        this.discord.on('pointerup', this.clickDiscord);
        this.add.existing(this.discord);

        // credits
        this.credits = new Button(this, 0, 0, 'Artwork Credits');
        this.credits.setOrigin(1, 1);
        this.credits.on('pointerup', this.clickCredits);
        this.add.existing(this.credits);

        // run tests
        this.testbutton = new Button(this, 0, 0, 'Run Tests');
        this.testbutton.setOrigin(1, 1);
        this.testbutton.on('pointerup', this.clickTests);
        this.add.existing(this.testbutton);

        // logout
        this.logout = new Button(this, 0, 0, 'Logout');
        this.logout.setOrigin(1, 1);
        this.logout.on('pointerup', this.clickLogout);
        this.add.existing(this.logout);

        this.events.on('shutdown', this.shutdown);
        this.scale.on('resize', this.resize);
        this.resize();

        this.worlds(); // kick off async
    }

    shutdown = () => {
        this.scale.off('resize', this.resize);
        this.buttons.length = 0;
    }

    worlds = async () => {
        // cleanup existing buttons on refresh
        for (let button of this.buttons) {
            for (let child of Object.values(button)) {
                if (child.destroy) child.destroy();
            }
        }
        this.buttons.length = 0;

        try {
            const worlds = await engine.worlds();
            worlds.push({ name: 'New World' });
            for (let i = 0; i < worlds.length; i++) {
                this.worldButton(worlds[i], i);
            }
        } catch (e) {
            // server/login problem
            this.retryButton();
        }

        this.resize();
    }

    worldButton = (world, idx) => {
        // main load button
        const load = new Button(this, 0, 0, world.name);
        load.setOrigin(.5, 0);
        load.setAlpha(0);
        this.add.existing(load);

        if (world.file) {
            load.on('pointerup', () => this.clickLoad(world.file));
        } else {
            load.on('pointerup', this.clickNew);
        }

        // remove button
        const remove = new Button(this, 0, 0, 'X');
        remove.setOrigin(0, 0);
        remove.setAlpha(0);
        this.add.existing(remove);

        if (world.file) {
            remove.on('pointerup', () => this.clickRemove(world.file));
        } else {
            remove.visible = false;
        }

        this.tweens.add({
            targets: [ load, remove ],
            alpha: 1,
            delay: 100 * idx,
            duration: 100,
            ease: 'Power2'
        });

        this.buttons.push({ newgame: !world.file, load, remove });
    }

    retryButton = () => {
        const text = this.add.text(0, 0, 'Error\nCouldn\'t download world list from server', { font: '18pt Calibri', fill: '#ffffff', align: 'center' });
        text.setOrigin(.5, 0);
        text.setAlpha(0);
        this.add.existing(text);

        const button = new Button(this, 0, 0, 'Retry');
        button.setOrigin(.5, 0);
        button.setAlpha(0);
        button.on('pointerup', this.clickRetry);
        this.add.existing(button);

        this.tweens.add({
            targets: [ button, text ],
            alpha: 1,
            delay: 100,
            duration: 100,
            ease: 'Power2'
        });

        this.buttons.push({ load: text });
        this.buttons.push({ load: button });
    }

    resize = () => {
        const camera = this.cameras.main;
        const x = camera.width / 2;

        this.text.setPosition(x, MARGIN);
        this.notes.setPosition(x, this.text.y + this.text.height);

        this.logo.setPosition(camera.width - MARGIN, camera.height - 50);
        this.discord.setPosition(camera.width - MARGIN, camera.height - MARGIN);
        this.credits.setPosition(this.discord.x - this.discord.width - MARGIN, this.discord.y);
        this.testbutton.setPosition(this.credits.x - this.credits.width - MARGIN, this.discord.y);
        this.logout.setPosition(this.testbutton.x - this.testbutton.width - MARGIN, this.discord.y);

        let y = this.notes.y + this.notes.height + 100;
        for (let button of this.buttons) {
            if (button.newgame) y += 20;

            button.load.setPosition(x, y);
            button.remove?.setPosition(x + button.load.width / 2 + 20, y);
            y += button.load.height + 10;
        }
    }

    clickNew = async () => {
        const name = window.prompt('World Name:');
        if (!name) return;

        this.scene.start('World');
        this.scene.start('HUD');

        this.scene.get('World').events.once('ready', () => engine.new(name));
    }

    clickLoad = async (file) => {
        this.scene.start('World');
        this.scene.start('HUD');

        this.scene.get('World').events.once('ready', () => engine.load(file));
    }

    clickRemove = async (file) => {
        if (!window.confirm('Are you sure you want to delete this world? This action cannot be undone.')) return;

        await engine.remove(file);
        this.worlds(); // kick off async
    }

    clickRetry = async () => {
        this.worlds(); // kick off async
    }

    clickDiscord = () => {
        window.open('https://discord.gg/rUpyrYZNDM', 'discord');
    }

    clickCredits = () => {
        window.open('/credits.html', 'credits');
    }

    clickTests = async () => {
        this.scene.start('World');
        this.scene.start('HUD');

        this.scene.get('World').events.once('ready', test.start);
    }

    clickLogout = async () => {
        localStorage.removeItem('authorization');
        delete window.artell.authorization;
        this.scene.start('Login');
    }
}