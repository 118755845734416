export default class Program {
    constructor(engine) {
        this.engine = engine;
    }

    actionlist = (object) => {
        if (object.action) return; // action already in progress
        if (!object.program?.code) return; // no program/code

        const step = ((object.step || 0) + (object.done ? 1 : 0)) % object.program.code.length;
        const action = object.program.code[step];

        // directly assign next action, so that updateObject doesn't get stuck in infinite loop
        object.step = step;
        object.action = action;
        delete object.done;
    }

    recipepicker = (object) => {
        // same impl as actionlist
        return this.actionlist(object);
    }
}