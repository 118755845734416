const sprites = {
    'base': (object) => ({
        atlas: 'atlas/base.png',
        frame: 'base'
    }),
    'chest': (object) => ({
        atlas: 'atlas/chests.png',
        frame: 'chest-closed'
    }),
    'bot': (object) => ({
        atlas: 'atlas/bot.png',
        frame: (object?.action?.end && object?.action?.id === 'move') ? [ `bot-${object?.face || 'south'}-1`, `bot-${object?.face || 'south'}-2`, `bot-${object?.face || 'south'}-3`, `bot-${object?.face || 'south'}-4`, `bot-${object?.face || 'south'}-5`, `bot-${object?.face || 'south'}-6`, `bot-${object?.face || 'south'}-7`, `bot-${object?.face || 'south'}-8` ] : `bot-${object?.face || 'south'}`
    }),
    'bot-farmer': (object) => ({
        atlas: 'atlas/harvesters.png',
        frame: (object?.action?.end && object?.action?.id === 'move') ? [ `bot-farmer-${object?.face || 'south'}-1`, `bot-farmer-${object?.face || 'south'}-2`, `bot-farmer-${object?.face || 'south'}-3`, `bot-farmer-${object?.face || 'south'}-4`, `bot-farmer-${object?.face || 'south'}-5`, `bot-farmer-${object?.face || 'south'}-6`, `bot-farmer-${object?.face || 'south'}-7`, `bot-farmer-${object?.face || 'south'}-8` ] : `bot-farmer-${object?.face || 'south'}`
    }),
    'bot-miner': (object) => ({
        atlas: 'atlas/harvesters.png',
        frame: (object?.action?.end && object?.action?.id === 'move') ? [ `bot-miner-${object?.face || 'south'}-1`, `bot-miner-${object?.face || 'south'}-2`, `bot-miner-${object?.face || 'south'}-3`, `bot-miner-${object?.face || 'south'}-4`, `bot-miner-${object?.face || 'south'}-5`, `bot-miner-${object?.face || 'south'}-6`, `bot-miner-${object?.face || 'south'}-7`, `bot-miner-${object?.face || 'south'}-8` ] : `bot-miner-${object?.face || 'south'}`
    }),
    'bot-transporter': (object) => ({
        atlas: 'atlas/bot-transporter.png',
        frame: (object?.action?.end && object?.action?.id === 'move') ? [ `bot-transporter-${object?.face || 'south'}-1`, `bot-transporter-${object?.face || 'south'}-2`, `bot-transporter-${object?.face || 'south'}-3`, `bot-transporter-${object?.face || 'south'}-4`, `bot-transporter-${object?.face || 'south'}-5`, `bot-transporter-${object?.face || 'south'}-6`, `bot-transporter-${object?.face || 'south'}-7`, `bot-transporter-${object?.face || 'south'}-8` ] : `bot-transporter-${object?.face || 'south'}`
    }),
    'tree': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree-${object?.growth || 0}`
    }),
    'tree2': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree2-${object?.growth || 0}`
    }),
    'tree3': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree3-${object?.growth || 0}`
    }),
    'tree4': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree4-${object?.growth || 0}`
    }),
    'tree5': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree5-${object?.growth || 0}`
    }),
    'tree6': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree6-${object?.growth || 0}`
    }),
    'tree-red': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree-red-${object?.growth || 0}`
    }),
    'tree-palm': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree-palm-${object?.growth || 0}`
    }),
    'tree-coconut': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree-coconut-${object?.growth || 0}`
    }),
    'tree-dead': (object) => ({
        atlas: 'atlas/trees.png',
        frame: `tree-dead`
    }),
    'wheat': (object) => ({
        atlas: 'atlas/crops.png',
        frame: `wheat-${object?.growth || 0}`
    }),
    'lettuce': (object) => ({
        atlas: 'atlas/crops.png',
        frame: `lettuce-${object?.growth || 0}`
    }),
    'tomato': (object) => ({
        atlas: 'atlas/crops.png',
        frame: `tomato-${object?.growth || 0}`
    }),
    'onion': (object) => ({
        atlas: 'atlas/crops.png',
        frame: `onion-${object?.growth || 0}`
    }),
    'strawberry': (object) => ({
        atlas: 'atlas/crops.png',
        frame: `strawberry-${object?.growth || 0}`
    }),
    'campfire': (object) => ({
        atlas: 'atlas/medieval.png',
        frame: [ 'campfire-1', 'campfire-2', 'campfire-3', 'campfire-4', 'campfire-5' ]
    }),
    'foundry': (object) => ({
        atlas: 'atlas/ore.png',
        frame: (object?.action?.end && object?.action?.id === 'craft') ? [ 'foundry-on-1', 'foundry-on-2', 'foundry-on-3' ] : 'foundry-off'
    }),
    'mill': (object) => ({
        atlas: 'atlas/mill.png',
        frame: 'mill'
    }),
    'sawmill': (object) => ({
        atlas: 'atlas/sawmill.png',
        frame: 'sawmill'
    }),
    'shed': (object) => ({
        atlas: 'atlas/shed.png',
        frame: 'shed'
    })
};

export default sprites;