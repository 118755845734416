import Phaser from 'phaser';

import { MARGIN } from './global';
import Menu from './Menu';
import RecipeBook from './RecipeBook';
import Hotbar from './Hotbar';
import Tutorial from './Tutorial';
import Keyboard from './Keyboard';

import Debug from './Debug';

export default class HUD extends Phaser.Scene {
    constructor() {
        super({ key: 'HUD' });
        this.keyboard = new Keyboard(this);
    }

    create = () => {
        this.hotbar = new Hotbar(this);
        this.hotbar.setOrigin(.5, 1);
        this.add.existing(this.hotbar);

        this.tutorial = new Tutorial(this);
        this.tutorial.setOrigin(.5, 0);
        this.add.existing(this.tutorial);

        this.menu = new Menu(this);
        this.menu.setOrigin(.5, .5);
        this.menu.visible = false;
        this.add.existing(this.menu);

        this.recipes = new RecipeBook(this);
        this.recipes.setOrigin(.5, .5);
        this.recipes.visible = false;
        this.add.existing(this.recipes);

        this.debug = new Debug(this, MARGIN, MARGIN);
        this.add.existing(this.debug);

        this.events.on('shutdown', this.shutdown);
        this.scale.on('resize', this.resize);
        this.resize();
    }

    shutdown = () => {
        this.scale.off('resize', this.resize);
    }

    update = () => {
        this.hotbar?.updateCoordinates();
    }

    clickMenu = () => {
        this.menu.visible = !this.menu.visible;
        this.recipes.visible = false;
    }

    clickRecipes = () => {
        this.menu.visible = false;
        this.recipes.visible = !this.recipes.visible;
    }

    resize = () => {
        const camera = this.cameras.main;

        this.menu.setPosition(camera.width / 2, camera.height / 2);
        this.recipes.setPosition(camera.width / 2, camera.height / 2);

        this.hotbar.setPosition(camera.width / 2, camera.height - MARGIN);
        this.tutorial.setPosition(camera.width / 2, MARGIN);
    }
}