import Phaser from 'phaser';
import Button from './Button';

import dfa from '../api/dfa';
import blizzard from '../api/blizzard';

export default class Login extends Phaser.Scene {
    constructor() {
        super({ key: 'Login' });
    }

    preload = () => {
        //this.load.audio('click', require('../sfx/click.ogg'));
    }

    create = () => {
        localStorage.removeItem('account'); // migration

        if (localStorage.getItem('authorization')) {
            window.artell.authorization = localStorage.getItem('authorization');
            this.scene.start('Splash');
            return;
        }

        this.text = this.add.text(0, 0, 'Artell is plotting, planning, thinking. But he is not ready yet.\n\nIgnore warnings and attempt boot sequence?', { font: '18pt Calibri', fill: '#ffffff', align: 'center' });
        this.text.setOrigin(.5, 1);

        this.bnet = new Button(this, 0, 0, 'Login with Battle.net');
        this.bnet.setOrigin(.5, 0);
        this.bnet.on('pointerup', this.loginBlizzard);
        this.add.existing(this.bnet);

        this.key = new Button(this, 0, 0, 'Login with Beta Key');
        this.key.setOrigin(.5, 0);
        this.key.on('pointerup', this.loginKey);
        this.add.existing(this.key);

        this.events.on('shutdown', this.shutdown);
        this.scale.on('resize', this.resize);
        this.resize();
    }

    shutdown = () => {
        this.scale.off('resize', this.resize);
    }

    resize = () => {
        const camera = this.cameras.main;
        const x = camera.width / 2;

        this.text.setPosition(x, camera.height / 2);
        this.bnet.setPosition(x, camera.height / 2 + 20);
        this.key.setPosition(x, this.bnet.y + this.bnet.height + 20);
    }

    loginBlizzard = async () => {
        blizzard.login(this.loginFinish);
    }

    loginKey = async () => {
        dfa.login(this.loginFinish);
    }

    loginFinish = (authorization) => {
        if (window.location.hostname === 'dev.artell.io' || authorization.startsWith('Bearer')) {
            // remember for dev environment and beta keys
            localStorage.setItem('authorization', authorization);
        }

        window.artell.authorization = authorization;
        this.scene.start('Splash');
    }
}