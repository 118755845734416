import map from '../generated/map.json';
import terrains from './terrains.json';

// prep data
const lookup = {};
for (let key of Object.keys(map.palette)) {
    const value = terrains[key];
    value.id = key;
    map.palette[key].forEach(tile => lookup[tile] = value);
}

const BEDROCK = map.palette.bedrock[0];

export default class Terrain {
    tile = (x, y) => {
        if (x < map.x || x >= (map.x + map.height)) return BEDROCK;
        if (y < map.y || y >= (map.y + map.width)) return BEDROCK;

        return map.tiles[y - map.y][x - map.x];
    }

    tiles = (x1, y1, x2, y2) => {
        const chunk = new Array(y2 - y1);
        for (let y = y1; y < y2; y++) {
            const row = new Array(x2 - x1);
            chunk[y - y1] = row;

            for (let x = x1; x < x2; x++) {
                row[x - x1] = this.tile(x, y);
            }
        }
        return chunk;
    }

    block = (x, y) => {
        const tile = this.tile(x, y);
        return lookup[tile];
    }

    bounds = () => {
        return {
            x: map.x,
            y: map.y,
            width: map.width,
            height: map.height
        };
    }
}