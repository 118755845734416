import { engine } from './global';

// support for keyboard input
export default class Keyboard {
    constructor(scene) {
        engine.on('update', this.onUpdate);
        engine.on('remove', this.onRemove);
        engine.on('select', this.onSelect);
        engine.on('load', () => this.onSelect(null));
        engine.on('unload', () => this.onSelect(null));

        window.addEventListener('keydown', event => {
            // don't perform action if user is typing input
            const active = document?.activeElement?.tagName?.toLowerCase();
            if (active === 'textarea' || active === 'input') return;

            // handle direct keybinds
            const key = event.key.toLowerCase();
            if (key === '~') return this.toggleDebug();
            if (key === 'escape') return scene.clickMenu();

            if (!this.selected) return;
            if (this.selected.power) return;

            // don't cancel actions when key is held down
            if (event.repeat && this.selected.action) return;

            if (engine.getObjectType(this.selected.type)?.actions?.move) {
                if (key === 'w') engine.updateObject(this.selected.id, { action: { id: 'move', direction: 'north' }});
                if (key === 'a') engine.updateObject(this.selected.id, { action: { id: 'move', direction: 'west' }});
                if (key === 's') engine.updateObject(this.selected.id, { action: { id: 'move', direction: 'south' }});
                if (key === 'd') engine.updateObject(this.selected.id, { action: { id: 'move', direction: 'east' }});
            }
        });
    }

    onUpdate = (object) => {
        if (object?.id === this.selected?.id) this.selected = object;
    }

    onSelect = (object) => {
        this.selected = object;
    }

    onRemove = (object) => {
        if (object?.id === this.selected?.id) this.selected = null;
    }

    toggleDebug = () => {
        if (localStorage.debug) {
            localStorage.removeItem('debug');
            engine.listeners.debug.forEach(listener => listener(false));
        } else {
            localStorage.setItem('debug', true);
            engine.listeners.debug.forEach(listener => listener(true));
        }
    }
}