import { engine } from './canvas/global';

// TODO Add assertions
const test = {
    start: async () => {
        await engine.load({
            version: 1,
            name: 'Test World',
            savetime: 1,
            gametime: 1,
            seed: 505660,
            removed: [],
            objects: {
                botturn: { id: 'botturn', type: 'bot', face: 'north', x: 10, y: 2, power: true, program: { language: 'actionlist', code: [
                    { id: 'turn', direction: 'east' },
                    { id: 'wait', amount: 100 },
                    { id: 'turn', direction: 'south' },
                    { id: 'wait', amount: 100 },
                    { id: 'turn', direction: 'west' },
                    { id: 'wait', amount: 100 },
                    { id: 'turn', direction: 'north' },
                    { id: 'wait', amount: 100 }
                ] } },
                botmove: { id: 'botmove', type: 'bot', face: 'north', x: 12, y: 2, power: true, program: { language: 'actionlist', code: [
                    { id: 'move', direction: 'east' },
                    { id: 'wait', amount: 500 },
                    { id: 'move', direction: 'south' },
                    { id: 'wait', amount: 500 },
                    { id: 'move', direction: 'west' },
                    { id: 'wait', amount: 500 },
                    { id: 'move', direction: 'north' },
                    { id: 'wait', amount: 500 }
                ] } },

                treecollide: { id: 'treecollide', type: 'tree', x: 14, y: 2 },
                botcollide: { id: 'botcollide', type: 'bot', face: 'south', x: 15, y: 2 },
                wheatcollide: { id: 'wheatcollide', type: 'wheat', x: 16, y: 2 },

                botdigdirt: { id: 'botdigdirt', type: 'bot', face: 'south', x: 10, y: 4, power: true, program: { language: 'actionlist', code: [ { id: 'dig' } ] } },
                botdigiron: { id: 'botdigiron', type: 'bot', face: 'south', x: 42, y: 7, power: true, program: { language: 'actionlist', code: [ { id: 'dig' } ] } },
                botdigtree: { id: 'botdigtree', type: 'bot', face: 'south', x: 14, y: 4, power: true, program: { language: 'actionlist', code: [ { id: 'dig' } ] } },
                botdigwheat: { id: 'botdigwheat', type: 'bot', face: 'south', x: 16, y: 4, power: true, program: { language: 'actionlist', code: [ { id: 'dig' } ] } },

                botdighide: { id: 'botdighide', type: 'bot', face: 'north', x: 20, y: 4, power: true, program: { language: 'actionlist', code: [ { id: 'dig' } ] } },

                botgive: { id: 'botgive', type: 'bot', face: 'north', x: 11, y: 18, inventory: { 'bread': 1000 }, power: true, program: { language: 'actionlist', code: [ { id: 'give', item: 'bread', amount: 2 } ] } },
                bottake: { id: 'bottake', type: 'bot', face: 'north', x: 11, y: 19, power: true, program: { language: 'actionlist', code: [ { id: 'take', item: 'bread', amount: 1 } ] } },

                bottalk: { id: 'bottalk', type: 'bot', face: 'south', x: 11, y: 21, message: { text: 'ow ow ow!' }}
            }
        });

        engine.setObject({ type: 'base', x: 10, y: 15, orders: [ { input: { bread: 1 } } ] });
        engine.setObject({ type: 'foundry', x: 14, y: 7, inventory: { 'wheat': 1000 } });
        engine.setObject({ type: 'foundry', x: 17, y: 7, inventory: { 'iron-ore': 1000 } });
        engine.setObject({ type: 'foundry', x: 20, y: 7, inventory: { 'stone': 1000 } });
        engine.setObject({ type: 'tree', x: 14, y: 5 });
        engine.setObject({ type: 'wheat', x: 16, y: 5 });
        engine.setObject({ type: 'tree', x: 20, y: 5, growth: 3 });

        test.interval = window.setInterval(test.timer, 500);
        engine.on('unload', test.stop);
    },
    stop: () => {
        window.clearInterval(test.interval);
        engine.off('unload', test.stop);
    },
    updateIf: (id, props) => {
        const object = engine.getObject(id);
        if (object && object.action && !object.action.error) return;

        engine.updateObject(id, props);
    },
    timer: () => {
        test.updateIf('botcollide', { action: { id: 'move', direction: engine.getObject('botcollide').face === 'east' ? 'west' : 'east' }});
    }
};

export default test;