import Phaser from 'phaser';
import { engine } from './global';

export default class Debug extends Phaser.GameObjects.Container {
    constructor(scene, x, y) {
        super(scene, x, y);

        engine.on('debug', this.onDebug);
        engine.on('remove', this.onRemove);
        engine.on('select', this.onSelect);
        engine.on('load', () => this.onSelect(null));
        engine.on('unload', () => this.onSelect(null));

        engine.on('update', this.onUpdate);

        const div = document.createElement('div');
        div.innerHTML = '<div id="debug" style="width: 200px; max-height: 50vh; overflow-y: auto; background-color: white; white-space: pre-wrap;"></div>';
        this.div = this.scene.add.dom(0, 0, div);
        this.div.setOrigin(0, 0);
        this.add(this.div);

        this.visible = Boolean(localStorage.debug);
    }

    onDebug = (on) => {
        this.visible = on;
        this.onUpdate(this.selected);
    }

    onSelect = (object) => {
        this.selected = object;
        this.onUpdate(this.selected);
    }

    onRemove = (object) => {
        if (object?.id !== this.selected?.id) return;
        this.selected = null;
        this.onUpdate(this.selected);
    }

    onUpdate = (object) => {
        if (!this.visible) return;
        if (object?.id !== this.selected?.id) return;
        this.selected = object;

        const div = document.getElementById('debug');
        div.innerText = object ? JSON.stringify(object, null, 1) : '';
    }
}