import Phaser from 'phaser';

import utils from '../engine/utils';
import { engine } from './global';

export default class Progress extends Phaser.GameObjects.Rectangle {
    constructor(scene) {
        super(scene, 0, 0, 1, -6, 0x696969, 1);
        this.setOrigin(0, 0);
        this.setStrokeStyle(1, 0xffffff, 1);
        this.visible = false;
    }

    setProgress = (start, end, countdown) => {
        this.start = start;
        this.end = end;
        this.countdown = countdown;
        this.update();

        window.clearInterval(this.interval);
        this.interval = window.setInterval(this.update, (this.end - this.start) / 100);
    }

    clearProgress = () => {
        window.clearInterval(this.interval);
        this.visible = false;
    }

    update = () => {
        const percent = utils.progress.percent(this.start, engine.now(), this.end);
        if (percent < 1) {
            this.visible = false;
        } else if (percent > 99) {
            this.visible = false;
            window.clearInterval(this.interval);
        } else {
            const width = this.countdown ? (100 - percent) : percent;
            this.setSize(width * this.maxWidth / 100, -6);
            this.visible = true;
        }
    }

    destroy = () => {
        window.clearInterval(this.interval);
        super.destroy();
    }
}