import Button from './Button';
import OriginContainer from './OriginContainer';
import Icon from './Icon';

import { engine, MARGIN } from './global';

const WIDTH = 600;
const regex = /{[^}]+}/g;

export default class Tutorial extends OriginContainer {
    constructor(scene) {
        super(scene);
        engine.on('tutorial', this.update);

        // reuse icons
        this.icons = {};
    }

    destroy = () => {
        engine.off('tutorial', this.update);
        super.destroy();
    }

    parse = (message) => {
        const m = message.match(regex) || [];

        return {
            message: message.replace(regex, ''),
            icons: m.filter(part => part.startsWith('{icon:')).map(part => part.substring(6).replace('}', ''))
        };
    }

    update = (message) => {
        this.visible = Boolean(message);
        if (!message) return;

        if (!this.background) {
            this.background = this.scene.add.rectangle(0, 0, WIDTH, 1, 0x333333);
            this.background.setOrigin(0, 0);
            this.container.add(this.background);
        }

        if (!this.title) {
            this.title = this.scene.add.text(MARGIN, MARGIN, 'Tutorial', { font: '18pt Calibri', fill: '#ffffff', wordWrap: { width: WIDTH - 2 * MARGIN, useAdvancedWrap: true } });
            this.title.setStroke(0x000000, 2);
            this.container.add(this.title);
        }

        if (!this.close) {
            this.close = new Button(this.scene, 0, 0, 'X');
            this.close.setOrigin(1, 0);
            this.close.on('pointerup', engine.tutorial.close);
            this.container.add(this.close);
        }
        this.close.setPosition(WIDTH, 0);

        if (!this.message) {
            this.message = this.scene.add.text(MARGIN, this.close.height + 4, '', { font: '12pt Calibri', fill: '#ffffff', wordWrap: { width: WIDTH - 2 * MARGIN, useAdvancedWrap: true } });
            this.message.setStroke(0x000000, 2);
            this.container.add(this.message);
        }

        const parsed = this.parse(message);
        this.message.setText(parsed.message + (parsed.icons?.length ? '\n\nIcon guide:' : ''));

        let x = MARGIN;
        let y = this.message.y + this.message.height + MARGIN;

        Object.values(this.icons).forEach(icon => icon.visible = false);
        for (let item of parsed.icons) {
            let icon = this.icons[item];

            if (!icon) {
                this.icons[item] = icon = new Icon(this.scene, item, null, true, false);
                this.container.add(icon);
            }
            icon.visible = true;
            icon.setPosition(x, y);

            x += icon.width + MARGIN;
        }
        y += Math.max(0, ...Object.values(this.icons).filter(icon => icon.visible).map(icon => icon.height));

        this.background.setSize(WIDTH, y + MARGIN);
        this.background.setInteractive();

        this.setSize(this.background.width, this.background.height);
    }
}