import utils from './utils';

import errors from './errors.json';

const WAIT = 24 * 60 * 60 * 1000;
const UNLIMITED = Number.POSITIVE_INFINITY;

class Helpers {
    constructor(engine) {
        this.engine = engine;
        this.errors();
    }

    // preps error codes
    errors = () => {
        for (let key of Object.keys(errors)) {
            errors[key].id = key;
        }
    }

    validate = (object) => {
        const now = this.engine.now();
        const action = object.action;
        const type = this.engine.getObjectType(object.type);
        const config = type.actions?.[action.id];
        let error = undefined;

        // validate
        if (!config) error = errors.invalidaction;

        // return common vars
        return { now, action, type, config, error };
    }

    // calculates coordinates from a position, moving a certain amount depending on the direction you're facing
    facing = (x, y, face, north, west, south, east) => {
        switch (face) {
            case 'north': return { x, y: y - north };
            case 'west': return { x: x - west, y };
            case 'south': return { x, y: y + south };
            case 'east': return { x: x + east, y };
            default: return { error: errors.noface };
        }
    }

    add = (inventory, items, capacity) => {
        inventory = this.transfer(inventory, items);

        const sum = Object.values(inventory).reduce((a, c) => a + c, 0);
        if (sum > capacity) return { error: errors.full };
        return inventory;
    }

    remove = (inventory, items) => {
        const negate = {};
        for (let item of Object.keys(items)) {
            negate[item] = -items[item];
        }
        return this.transfer(inventory, negate);
    }

    transfer = (inventory, items) => {
        inventory = inventory ? { ...inventory } : {};
        if (!items) return inventory;

        for (let item of Object.keys(items)) {
            inventory[item] ??= 0;

            const amount = items[item];
            if (isNaN(amount)) return { error: errors.nan };

            inventory[item] += amount;

            if (inventory[item] < 0) return { error: errors.noitem };
            if (inventory[item] === 0) delete inventory[item];
        }

        return inventory;
    }

    transferTime = (object, items) => {
        const speed = 250;
        const amount = Object.values(items).reduce((s, item) => s + item, 0);
        return Math.floor(speed * Math.pow(amount, 0.9)); // bulk discount
    }
}

export default class Actions {
    constructor(engine) {
        this.engine = engine;
        this.helpers = new Helpers(engine);
    }

    wait = (object) => {
        const action = object.action;
        const now = this.engine.now();

        const amount = action.amount;
        if (isNaN(amount)) {
            return { action: { ...action, error: errors.nan } };
        }

        if (!action.end) {
            // initialize action
            return { action: { ...action, start: now, end: now + amount }};
        } else if (now >= action.end) {
            // finalize action
            return { action: undefined, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    move = (object) => {
        const { action, type, now, error: e1 } = this.helpers.validate(object);
        if (e1) return { action: { ...action, error: e1 } };

        // check collision
        const { x, y, error: e2 } = this.helpers.facing(object.x, object.y, action.direction, 1, 1, 1, 1);
        if (e2) return { action: { ...action, error: e2 } };

        if (this.engine.collision(x, y, type.width, type.height, object.id)) {
            return { action: { ...action, error: errors.collision }, face: action.direction };
        }

        // check terrain
        for (let dx = x; dx < x + type.width; dx++) {
            for (let dy = y; dy < y + type.height; dy++) {
                const block = this.engine.state.terrain.block(dx, dy);
                if (!block?.target?.move) {
                    return { action: { ...action, error: errors.collision }, face: action.direction };
                }
            }
        }

        if (!action.end) {
            // initialize action
            return { face: action.direction, action: { ...action, start: now, end: now + object.stats.move }};
        } else if (now >= action.end) {
            // finalize action
            return { action: undefined, x, y, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    turn = (object) => {
        const { action, now, error } = this.helpers.validate(object);
        if (error) return { action: { ...action, error } };

        if (!action.end) {
            // initialize action
            return { action: { ...action, start: now, end: now + object.stats.turn }};
        } else if (now >= action.end) {
            // finalize action
            return { action: undefined, face: action.direction, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    dig = (object) => {
        const { action, type, now, error: e1 } = this.helpers.validate(object);
        if (e1) return { action: { ...action, error: e1 } };

        const { x, y, error: e2 } = this.helpers.facing(object.x, object.y, object.face, 1, 1, type.height, type.width);
        if (e2) return { action: { ...action, error: e2 } };

        const collide = this.engine.getObjectAt(x, y);
        const block = collide ? this.engine.getObjectType(collide.type) : this.engine.state.terrain.block(x, y);
        if (!block?.target?.dig) return { action: { ...action, error: errors.nodig } };

        if (!action.end) {
            // initialize action
            return { action: { ...action, start: now, end: now + object.stats.dig }};
        } else if (now >= action.end) {
            // finalize action
            // TODO Should dig drops be random when nothing else is?
            const drop = utils.frequency.choose(block.target.dig, Math.random());
            const inventory = this.helpers.add(object.inventory, drop.output, object.stats.inventory);
            if (inventory.error) return { action: { ...action, error: inventory.error } };
            return { action: undefined, inventory, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    plant = (object) => {
        return this.build(object); // alias
    }

    build = (object) => {
        const { action, type, now, error: e1 } = this.helpers.validate(object);
        if (e1) return { action: { ...action, error: e1 } };

        const totype = this.engine.getObjectType(action.type);
        if (!totype) return { action: { ...action, error: errors.noobject } };

        const toconfig = totype?.target?.[action.id];
        if (!toconfig) return { action: { ...action, error: errors.nobuild } };

        const { x, y, error: e2 } = this.helpers.facing(object.x, object.y, object.face, totype.height, totype.width, type.height, type.width);
        if (e2) return { action: { ...action, error: e2 } };

        if (!action.end) {
            // check collision
            if (this.engine.collision(x, y, totype.width, totype.height)) {
                return { action: { ...action, error: errors.collision } };
            }

            // check terrain
            if (!this.engine.buildable(x, y, totype.width, totype.height)) {
                return { action: { ...action, error: errors.collision } };
            }

            // check inventory
            const inventory = this.helpers.remove(object.inventory, toconfig.input);
            if (inventory.error) return { action: { ...action, error: inventory.error } };

            // initialize action
            this.engine.setObject({ type: action.type, x, y, player: true, internal: toconfig.input, ghost: true });
            return { inventory, action: { ...action, start: now, end: now + toconfig.time }};
        } else if (now >= action.end) {
            // finalize action
            const to = this.engine.getObjectAt(x, y);
            this.engine.updateObject(to.id, { ghost: undefined });
            return { action: undefined, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    harvest = (object) => {
        return this.destroy(object); // alias
    }

    destroy = (object) => {
        const { action, type, now, error: e1 } = this.helpers.validate(object);
        if (e1) return { action: { ...action, error: e1 } };

        const { x, y, error: e2 } = this.helpers.facing(object.x, object.y, object.face, 1, 1, type.height, type.width);
        if (e2) return { action: { ...action, error: e2 } };

        const to = this.engine.getObjectAt(x, y);
        if (!to) return { action: { ...action, error: errors.noobject } };

        const toconfig = this.engine.getObjectType(to.type).target?.[action.id];
        if (!toconfig) return { action: { ...action, error: errors.nodestroy } };

        if (!action.end) {
            // initialize action
            return { action: { ...action, start: now, end: now + toconfig.time }};
        } else if (now >= action.end) {
            // finalize action
            let inventory = this.helpers.add(object.inventory, to.inventory, object.stats.inventory);
            inventory = this.helpers.add(inventory, to.internal, object.stats.inventory);
            inventory = this.helpers.add(inventory, to.buffer, object.stats.inventory);
            if (inventory.error) return { action: { ...action, error: inventory.error } };

            this.engine.removeObject(to);
            return { action: undefined, inventory, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    give = (object) => {
        const { action, now, type, error: e1 } = this.helpers.validate(object);
        if (e1) return { action: { ...action, error: e1 } };

        const { x, y, error: e2 } = this.helpers.facing(object.x, object.y, object.face, 1, 1, type.height, type.width);
        if (e2) return { action: { ...object.action, error: e2 } };

        const to = this.engine.getObjectAt(x, y);
        if (!to) return { action: { ...action, error: errors.noobject } };
        if (to.ghost || !this.engine.getObjectType(to.type)?.target?.give) return { action: { ...object.action, error: errors.notransfer } };

        const items = { [action.item]: action.amount };
        const frominv = this.helpers.remove(object.inventory, items);
        if (frominv.error) return { action: { ...action, error: frominv.error } };

        const toinv = this.helpers.add(to.inventory, items, to.stats.inventory);
        if (toinv.error) return { action: { ...action, error: toinv.error } };

        if (!action.end) {
            // initialize action
            const time = this.helpers.transferTime(object, items);
            return { action: { ...action, start: now, end: now + time }};
        } else if (now >= action.end) {
            // finalize action
            this.engine.updateObject(to.id, { inventory: toinv });
            return { inventory: frominv, action: undefined, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    take = (object) => {
        const { action, now, type, error: e1 } = this.helpers.validate(object);
        if (e1) return { action: { ...action, error: e1 } };

        const { x, y, error: e2 } = this.helpers.facing(object.x, object.y, object.face, 1, 1, type.height, type.width);
        if (e2) return { action: { ...object.action, error: e2 } };

        const from = this.engine.getObjectAt(x, y);
        if (!from) return { action: { ...action, error: errors.noobject } };
        if (from.ghost || !this.engine.getObjectType(from.type)?.target?.take) return { action: { ...object.action, error: errors.notransfer } };

        const items = { [action.item]: action.amount };
        const frominv = this.helpers.remove(from.inventory, items);
        if (frominv.error) return { action: { ...action, error: frominv.error } };

        const toinv = this.helpers.add(object.inventory, items, object.stats.inventory);
        if (toinv.error) return { action: { ...action, error: toinv.error } };

        if (!action.end) {
            // initialize action
            const time = this.helpers.transferTime(object, items);
            return { action: { ...action, start: now, end: now + time }};
        } else if (now >= action.end) {
            // finalize action
            this.engine.updateObject(from.id, { inventory: frominv });
            return { inventory: toinv, action: undefined, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    install = (object) => {
        const { action, now, config, error } = this.helpers.validate(object);
        if (error) return { action: { ...action, error } };

        // make sure upgrade is allowed
        if (!config[action.item]) return { action: { ...action, error: errors.invalidupgrade } };

        const items = { [action.item]: 1 };
        const inventory = this.helpers.remove(object.inventory, items);
        if (inventory.error) return { action: { ...action, error: inventory.error } };

        const upgrades = this.helpers.add(object.upgrades, items, UNLIMITED);
        if (upgrades[action.item] > 1) return { action: { ...action, error: errors.alreadyinstalled } };

        if (!action.end) {
            // initialize action
            const time = this.helpers.transferTime(object, items);
            return { action: { ...action, start: now, end: now + time }};
        } else if (now >= action.end) {
            // finalize action
            return { inventory, upgrades, action: undefined, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    uninstall = (object) => {
        const { action, now, error } = this.helpers.validate(object);
        if (error) return { action: { ...action, error } };

        const items = { [action.item]: 1 };
        const upgrades = this.helpers.remove(object.upgrades, items);
        if (upgrades.error) return { action: { ...action, error: upgrades.error } };

        const inventory = this.helpers.add(object.inventory, items, object.stats.inventory);
        if (inventory.error) return { action: { ...action, error: inventory.error } };

        // TODO Don't allow uninstall if it results in a violation. e.g. Would be over inventory space

        if (!action.end) {
            // initialize action
            const time = this.helpers.transferTime(object, items);
            return { action: { ...action, start: now, end: now + time }};
        } else if (now >= action.end) {
            // finalize action
            return { inventory, upgrades, action: undefined, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    grow = (object) => {
        const { action, now, config, error } = this.helpers.validate(object);
        if (error) return { action: { ...action, error } };

        const growth = object.growth || 0;
        if (growth >= config.length) {
            // done growing, put into pause mode
            return { action: { ...action, start: now + WAIT } };
        }

        const stage = config[growth];

        if (!action.end) {
            // initialize action
            return { growth, action: { ...action, start: now, end: now + stage.time }};
        } else if (now >= action.end) {
            // finalize action
            const internal = this.helpers.add(object.internal, stage.output, UNLIMITED);
            if (internal.error) return { action: { ...action, error: internal.error } };

            return { internal, growth: object.growth + 1, action: undefined, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    craft = (object) => {
        const { action, now, config, error } = this.helpers.validate(object);
        if (error) return { action: { ...action, error } };

        if (!object.inventory) return { action: { ...action, start: now + WAIT } }; // wait for something to craft

        if (!action.end) {
            // initialize action
            // find a recipe that matches
            let match = null;
            for (let recipe of config) {
                if (Object.keys(recipe.output).some(item => !action.item || (item === action.item))) {
                    if (Object.keys(recipe.input).every(item => object.inventory[item] >= recipe.input[item])) {
                        match = recipe;
                        break;
                    }
                }
            }
            if (!match) return { action: { ...action, start: now + WAIT } }; // wait for something to craft

            return { action: { ...action, start: now, end: now + match.time, input: match.input, output: match.output }};
        } else if (now >= action.end) {
            // finalize action
            let inventory = this.helpers.remove(object.inventory, action.input);
            if (inventory.error) return { action: { ...action, error: inventory.error } };

            inventory = this.helpers.add(inventory, action.output, object.stats.inventory);
            if (inventory.error) return { action: { ...action, error: inventory.error } };

            return { inventory, action: undefined, done: true };
        } else {
            // re-queue
            return { action };
        }
    }

    deplete = (object) => {
        const { action, now, error } = this.helpers.validate(object);
        if (error) return { action: { ...action, error } };
        if (!object.orders) return { action: { ...action, error: errors.invalidaction } };

        const depletions = object.depletions || 0;
        const match = object.orders[Math.min(depletions, object.orders.length - 1)];

        let inventory = this.helpers.remove(object.inventory, match.input);
        if (inventory.error) return { action: { ...action, start: now + WAIT, input: match.input } }; // wait for delivery

        if (!action.end) {
            // initialize action
            return { action: { ...action, start: now, end: now + 1000, input: match.input }};
        } else if (now >= action.end) {
            // finalize action
            for (let bot of this.engine.getObjectHome(object.id)) {
                // assign any bots that are tied to this base
                if (!bot.player) this.engine.updateObject(bot.id, { player: true });
            }

            inventory = this.helpers.add(inventory, match.output, object.stats.inventory);
            if (inventory.error) return { action: { ...action, error: inventory.error } };

            return { inventory, depletions: depletions + 1, action: undefined, done: true };
        } else {
            // re-queue
            return { action };
        }
    }
}