import Phaser from 'phaser';

import Sprite from './Sprite';
import { engine, GRID, CHUNK, wasDragged } from './global';

export default class World extends Phaser.Scene {
    constructor() {
        super({ key: 'World' });

        this.sprites = {};
        this.map = {};

        engine.on('unload', this.onUnload);
        engine.on('load', this.onLoad);
        engine.on('update', this.onUpdate);
        engine.on('remove', this.onRemove);
    }

    onUnload = () => {
        for (let sprite of Object.values(this.sprites)) {
            sprite.destroy();
        }
        this.sprites = {};

        for (let map of Object.values(this.map)) {
            map.destroy();
        }
        this.map = {};
    }

    onLoad = () => {
        const camera = this.cameras.main;

        const bounds = engine.state.terrain.bounds();
        camera.setBounds(GRID * bounds.x, GRID * bounds.y, GRID * bounds.width, GRID * bounds.height);

        const viewport = engine.getViewport();
        camera.centerOn(viewport.x, viewport.y);
        camera.setZoom(viewport.zoom);

        this.debounce();
    }

    onUpdate = (object) => {
        let sprite = this.sprites[object.id];
        if (!sprite) {
            sprite = new Sprite(this);
            this.sprites[object.id] = sprite;
            this.add.existing(sprite);
        }

        sprite.onChange(object);
    }

    onRemove = (object) => {
        const sprite = this.sprites[object.id];
        if (sprite) sprite.destroy();
        delete this.sprites[object.id];
    }

    create = () => {
        this.events.on('shutdown', this.shutdown);
        this.input.on('pointerup', this.deselect);
        this.input.on('pointermove', this.scroll);
        this.input.on('wheel', this.zoom);
        this.scale.on('resize', this.resize);
    }

    shutdown = () => {
        this.input.off('pointerup', this.deselect);
        this.input.off('pointermove', this.scroll);
        this.input.off('wheel', this.zoom);
        this.scale.off('resize', this.resize);
    }

    deselect = (pointer, currentlyOver) => {
        if (currentlyOver?.length === 0 && !wasDragged(pointer)) {
            // only deselect if nothing else was clicked
            // if needed, we can pass custom info via pointer.event.customproperty
            engine.listeners.select.forEach(listener => listener(null));
        }
    }

    scroll = (pointer) => {
        if (!pointer.isDown) return;

        const camera = this.cameras.main;
        camera.scrollX -= (pointer.x - pointer.prevPosition.x) / camera.zoom;
        camera.scrollY -= (pointer.y - pointer.prevPosition.y) / camera.zoom;

        engine.setViewport({ x: camera.worldView.centerX, y: camera.worldView.centerY });

        this.debounce();
    }

    zoom = (pointer, over, deltaX, deltaY, deltaZ) => {
        // TODO Handle mobile pinch events too
        if (!deltaY) return;

        // setup a list of allowed zooms, to avoid rounding errors
        const allowed = [];
        for (let i = 1; i > .2; i /= 1.1) {
            allowed.unshift(+i.toFixed(2));
        }
        for (let i = 1.1; i < 10; i *= 1.1) {
            allowed.push(+i.toFixed(2));
        }

        let zoom = null;
        const camera = this.cameras.main;
        if (deltaY > 0) {
            allowed.reverse();
            zoom = allowed.find(z => z < camera.zoom);
        } else {
            zoom = allowed.find(z => z > camera.zoom);
        }

        if (zoom) {
            camera.zoomTo(zoom, 25);
            engine.setViewport({ zoom });
            this.debounce();
        }
    }

    resize = () => {
        const view = this.cameras.main.worldView;
        engine.setViewport({ x: view.centerX, y: view.centerY });

        this.debounce();
    }

    debounce = () => {
        window.clearTimeout(this.timeout);
        this.timeout = window.setTimeout(this.terrain, 30);
    }

    terrain = () => {
        const view = this.cameras.main.worldView;

        const chunkxmin = Math.floor(Math.floor(view.left / GRID) / CHUNK) - 1;
        const chunkxmax = Math.floor(Math.floor(view.right / GRID) / CHUNK) + 1;
        const chunkymin = Math.floor(Math.floor(view.top / GRID) / CHUNK) - 1;
        const chunkymax = Math.floor(Math.floor(view.bottom / GRID) / CHUNK) + 1;

        const visited = new Set();
        for (let chunkx = chunkxmin; chunkx < chunkxmax; chunkx++) {
            for (let chunky = chunkymin; chunky < chunkymax; chunky++) {
                const key = chunkx + ',' + chunky;
                visited.add(key);
                if (this.map[key]) continue;

                // setup coordinates
                const tilex = chunkx * CHUNK;
                const tiley = chunky * CHUNK;
                const worldx = tilex * GRID;
                const worldy = tiley * GRID;

                // create map
                const map = this.make.tilemap({ tileWidth: GRID, tileHeight: GRID, width: CHUNK, height: CHUNK });
                this.map[key] = map;

                // TODO still seeing lines when zooming out
                const tiles = map.addTilesetImage('terrain', 'terrain.png', GRID, GRID, 1, 2);
                const layer = map.createBlankLayer('terrain', tiles, worldx, worldy);
                layer.putTilesAt(engine.state.terrain.tiles(tilex, tiley, tilex + CHUNK, tiley + CHUNK), 0, 0);
                layer.setDepth(-999999999);
            }
        }

        for (let key of Object.keys(this.map)) {
            if (visited.has(key)) continue;

            this.map[key].destroy();
            delete this.map[key];
        }
    }
}