import utils from '../engine/utils';

const base = 'https://ftdqksph6chq3mev7qghtpf3wm0imzuy.lambda-url.us-east-1.on.aws/';

async function get(endpoint, headers) {
    try {
        const params = {
            method: 'get',
            headers
        }
        return await fetch(base + endpoint, params);
    } catch (e) {
        // often happens due to CORS, so fake an HTTP error code
        return { status: 500 };
    }
}

async function del(endpoint, headers) {
    try {
        const params = {
            method: 'delete',
            headers
        };
        return await fetch(base + endpoint, params);
    } catch (e) {
        // often happens due to CORS, so fake an HTTP error code
        return { status: 500 };
    }
}

async function post(endpoint, payload, headers) {
    try {
        const params = {
            method: 'post',
            headers,
            body: JSON.stringify(payload)
        };
        return await fetch(base + endpoint, params);
    } catch (e) {
        // often happens due to CORS, so fake an HTTP error code
        return { status: 500 };
    }
}

// oauth-like workflow to input a beta key
async function login(callback) {
    try {
        const key = prompt('Enter Beta Key:');
        if (key === null) return; // Cancel

        const s = key?.split('-');
        if (!s) throw new Error();

        const check = s.length === 3 && s[0].length > 0 && s[1].length === 6 && s[2].length === 6;
        if (!check) throw new Error();

        const response = await get('worlds', { 'Authorization': 'Bearer ' + key });
        if (response.status !== 200) throw new Error();

        if (!Array.isArray(await response.json())) throw new Error();

        // valid key
        return callback('Bearer ' + key);
    } catch (e) {

    }

    alert('Invalid Key');
}

async function list() {
    const response = await get('worlds', { 'Authorization': window.artell.authorization });
    if (response.status !== 200) throw new Error('Could not load worlds');
    return response.json();
}

async function load(world) {
    const response = await get('worlds/' + encodeURIComponent(world), { 'Authorization': window.artell.authorization });
    if (response.status !== 200) throw new Error('Could not load world');

    const json = await response.json();
    if (!json.id) json.id = world;
    return json;
}

async function save(world, payload) {
    if (!world) world = utils.uid();
    payload.id = world;

    const response = await post('worlds/' + encodeURIComponent(world), payload, { 'Authorization': window.artell.authorization });
    if (response.status !== 201) throw new Error('Could not save world');

    return payload;
}

async function remove(world) {
    const response = await del('worlds/' + encodeURIComponent(world), { 'Authorization': window.artell.authorization });
    if (response.status !== 204) throw new Error('Could not delete world');
}

const api = { login, list, save, load, remove };
export default api;