import Engine from '../engine/Engine';

// engine singleton shared by all UI code
export const engine = new Engine();

// actions that show progress bar
export const PROGRESS_BAR = new Set([ 'craft', 'deplete', 'dig', 'plant', 'harvest', 'build', 'destroy', 'give', 'take', 'install', 'uninstall' ]);
export const COUNTDOWN_BAR = new Set([ ]);

export const GRID = 32;
export const CHUNK = 100;
export const CLICK_TOLERANCE = 10;
export const MARGIN = 10;

export const wasDragged = (pointer) => {
    return (Math.abs(pointer.downX - pointer.upX) > CLICK_TOLERANCE) || (Math.abs(pointer.downY - pointer.upY) > CLICK_TOLERANCE);
}