export default class Collision {
    constructor() {
        this.map = {};
    }

    add = (x, y, width, height, id) => {
        for (let dx = x; dx < x + width; dx++) {
            for (let dy = y; dy < y + height; dy++) {
                this.map[dx + ',' + dy] = id;
            }
        }
    }

    get = (x, y) => {
        return this.map[x + ',' + y];
    }

    check = (x, y, width, height, id) => {
        for (let dx = x; dx < x + width; dx++) {
            for (let dy = y; dy < y + height; dy++) {
                const pre = this.map[dx + ',' + dy];
                if (pre && pre !== id) return true;
            }
        }

        return false;
    }

    remove = (x, y, width, height) => {
        for (let dx = x; dx < x + width; dx++) {
            for (let dy = y; dy < y + height; dy++) {
                delete this.map[dx + ',' + dy];
            }
        }
    }
}