import Phaser from 'phaser';

import sprites from './sprites';
import { engine, GRID } from './global';
import Tooltip from './Tooltip';
import utils from '../engine/utils';

export default class Icon extends Phaser.GameObjects.Container {
    constructor(scene, item, quantity, label = false, tooltip = true) {
        super(scene);

        this.item = item;
        const sprite = engine.getItemType(item) || engine.getAction(item) || sprites[item]();

        this.image = new Phaser.GameObjects.Image(this.scene, GRID / 2, GRID / 2, sprite.atlas, Array.isArray(sprite.frame) ? sprite.frame[0] : sprite.frame);
        this.image.setOrigin(.5, .5);

        // scale image but keep proportions
        this.image.setScale(GRID / Math.max(this.image.width, this.image.height));

        this.add(this.image);

        if (tooltip) {
            this.image.setInteractive(); // no hand cursor since there's only a tooltip
            this.image.on('pointerover', this.hover);
            this.image.on('pointerout', this.reset);

            this.tooltip = new Tooltip(this.scene, item, quantity);
            this.tooltip.setOrigin(0, 1);
            this.scene.add.existing(this.tooltip);
        }

        if (quantity) this.setQuantity(quantity);
        if (label) {
            this.label = this.scene.add.text(0, GRID, engine.getType(this.item)?.name, { font: '10pt Calibri', fill: '#ffffff' });
            this.label.setStroke(0x000000, 3);
            this.add(this.label);
        }

        this.setSize(Math.max(GRID, this.label?.width || 0), GRID + (this.label?.height || 0));
    }

    hover = () => {
        // position in the world, for proper depth
        const matrix = this.getWorldTransformMatrix();
        this.tooltip.setPosition(matrix.getX(0, 0), matrix.getY(0, 0));
        this.tooltip.show();
    }

    reset = () => {
        this.tooltip.hide();
    }

    on = (event, fn, context) => {
        this.image.on(event, fn, context);
        this.image.input.cursor = 'pointer'; // use hand cursor now that there's actually an event
    }

    setQuantity = (quantity) => {
        if (!this.quantity) {
            this.quantity = this.scene.add.text(GRID, GRID, '', { font: '10pt Calibri', fill: '#ffffff' });
            this.quantity.setStroke(0x000000, 3);
            this.quantity.setOrigin(1, 1);
            this.add(this.quantity);
        }

        const timer = this.item === 'wait';
        this.quantity.setText(quantity ? (timer ? utils.number.time(quantity) : utils.number.compact(quantity)) : '');
        this.tooltip?.setQuantity(quantity);
    }

    setSelected = (selected) => {
        if (!this.selected) {
            this.selected = this.scene.add.rectangle(0, 0, GRID, GRID);
            this.selected.setStrokeStyle(1, 0xffffff, 1);
            this.selected.setOrigin(0, 0);
            this.addAt(this.selected, 0);
        }

        this.selected.visible = selected;
    }
}