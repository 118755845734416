import OriginContainer from './OriginContainer';
import Button from './Button';

import { engine, MARGIN } from './global';

export default class Menu extends OriginContainer {
    constructor(scene) {
        super(scene);

        const snap = (prev) => prev.y + prev.height + MARGIN;

        // save
        this.save = new Button(this.scene, MARGIN, MARGIN, 'Save World');
        this.save.on('pointerup', this.clickSave);
        this.container.add(this.save);

        // debug
        this.debug = new Button(this.scene, MARGIN, snap(this.save) + MARGIN, 'Debug');
        this.debug.on('pointerup', this.clickDebug);
        this.container.add(this.debug);

        // exit to menu
        this.exit = new Button(this.scene, MARGIN, snap(this.debug) + MARGIN, 'Main Menu');
        this.exit.on('pointerup', this.clickExit);
        this.container.add(this.exit);

        // back to game
        this.back = new Button(this.scene, MARGIN, snap(this.exit), 'Back to Game');
        this.back.on('pointerup', this.clickBack);
        this.container.add(this.back);

        const width = Math.max(...this.container.list.map(o => o.width));
        const height = this.container.list.reduce((a, o) => a + o.height + MARGIN, 0);

        this.background = this.scene.add.rectangle(0, 0, width + 2 * MARGIN, height + 3 * MARGIN, 0x333333);
        this.background.setOrigin(0, 0);
        this.background.setStrokeStyle(1, 0xffffff, 1);
        this.container.addAt(this.background, 0);

        this.setSize(this.background.width, this.background.height);
        this.setOrigin(0, 0); // used to set initial position
    }

    clickSave = () => {
        engine.save();
        this.visible = false;
    }

    clickDebug = () => {
        if (localStorage.debug) {
            localStorage.removeItem('debug');
            engine.listeners.debug.forEach(listener => listener(false));
        } else {
            localStorage.setItem('debug', true);
            engine.listeners.debug.forEach(listener => listener(true));
        }
    }

    clickExit = () => {
        if (!window.confirm('Any unsaved changes will be lost. Continue?')) return;

        engine.exit();

        // make sure both scenes stop
        this.scene.scene.stop('World');
        this.scene.scene.stop('HUD');

        this.scene.scene.start('MainMenu');
    }

    clickBack = () => {
        this.visible = false;
    }
}