const BASE = 'https://ftdqksph6chq3mev7qghtpf3wm0imzuy.lambda-url.us-east-1.on.aws/';
const CLIENTID = '92391fa1c3384d66bc855ad97d63b34a';

// starts oauth workflow
function login(callback) {
    const state = 'blizzard' + Date.now().toString(36);
    const listener = event => {
        if (event?.data?.state !== state) return;

        callback(event.data.authorization);
        window.removeEventListener('message', listener);
    };

    window.addEventListener('message', listener);

    const stage = 'https://' + window.location.hostname + '/';
    const url = 'https://oauth.battle.net/oauth/authorize?client_id=' + encodeURIComponent(CLIENTID) + '&state=' + encodeURIComponent(state) + '&redirect_uri=' + encodeURIComponent(stage) + '&response_type=code';
    window.open(url, '_blank');
}

// handles user returning
function handleOauthRedirect() {
    const query = new URLSearchParams(document.location.search);
    const state = query.get('state');

    if (state?.startsWith('blizzard')) {
        render(query.get('code'), state); // async
        return true;
    } else {
        return false;
    }
}

async function render(code, state) {
    // TODO Render a better redirect page
    const div = document.createElement('h1');
    div.innerText = 'Validating login, please wait...';
    document.body.appendChild(div);

    try {
        const response = await fetch(BASE + 'blizzard/' + encodeURIComponent(code));
        if (response.status !== 200) throw new Error(); // will be caught

        const json = await response.json();
        if (!json.access_token) throw new Error(); // will be caught

        window.opener.postMessage({ state, authorization: 'Blizzard ' + json.access_token }, '*');

        div.innerText = 'Login succeeded. You may now close this window.';
        window.close();
    } catch (e) {
        div.innerText = 'Login failed';
    }
}

const api = { login, handleOauthRedirect };
export default api;