import OriginContainer from './OriginContainer';
import Icon from './Icon';
import { MARGIN } from './global';

const WIDTH = 600;
const ICONMARGIN = 4;
const ICONHEIGHT = 32;
const ICONWIDTH = 32;

export default class Inventory extends OriginContainer {
    constructor(scene, x, y, label) {
        super(scene, x, y);

        this.label = this.scene.add.text(MARGIN, MARGIN, label, { font: '12pt Calibri', fill: '#ffffff' });
        this.label.setStroke(0x000000, 3);
        this.container.add(this.label);

        this.status = this.scene.add.text(WIDTH - MARGIN, MARGIN, '', { font: '12pt Calibri', fill: '#ffffff' });
        this.status.setOrigin(1, 0);
        this.status.setStroke(0x000000, 3);
        this.container.add(this.status);

        // reuse icons
        this.icons = {};
    }

    setStatus = (status) => {
        this.status.setText(status);
    }

    update = (inventory) => {
        Object.values(this.icons).forEach(icon => icon.visible = false);

        let x = MARGIN;
        let y = this.label.y + this.label.height;

        for (let item of Object.keys(inventory)) {
            let icon = this.icons[item];

            if (!icon) {
                this.icons[item] = icon = new Icon(this.scene, item);
                this.container.add(icon);
            }
            icon.visible = true;
            icon.setQuantity(inventory[item]);
            icon.setPosition(x, y);

            x += ICONWIDTH + ICONMARGIN;

            if (x > 470) {
                x = MARGIN;
                y += ICONHEIGHT;
            }
        }

        this.setSize(x, y + ICONHEIGHT);
    }
}