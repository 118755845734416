import OriginContainer from './OriginContainer';
import { MARGIN } from './global';

const COLOR = 0x333333;
const COLOR_HOVER = 0x444444;
const COLOR_DOWN = 0x555555;
const COLOR_ACTIVE = 0x999999;

export default class Button extends OriginContainer {
    constructor(scene, x, y, text, options) {
        super(scene, x, y);

        this.margin = options?.margin || MARGIN;

        // background
        this.background = this.scene.add.rectangle(0, 0, 1, 1, COLOR);
        this.background.setStrokeStyle(1, 0xffffff, 1);
        this.background.setOrigin(0, 0);
        this.container.add(this.background);

        this.background.setInteractive({ useHandCursor: true });
        this.background.on('pointerover', this.hover);
        this.background.on('pointerdown', this.down);
        this.background.on('pointerup', this.click);
        this.background.on('pointerout', this.reset);

        // gives error on safari, maybe lack of .ogg support
        //this.sfx = this.scene.sound.add('click');

        // text
        this.text = this.scene.add.text(this.margin, this.margin, text, { font: (options?.fontSize || 18) + 'pt Calibri', fill: '#ffffff' });
        this.text.setStroke(0x000000, 3);
        this.container.add(this.text);

        this.setText(text); // will update sizing
    }

    on = (event, fn, context) => {
        if (event === 'pointerup') {
            this.handler = fn;
        } else {
            super.on(event, fn, context);
        }
    }

    reset = () => {
        this.background.setFillStyle(this.btnactive ? COLOR_ACTIVE : COLOR);
    }

    hover = () => {
        this.background.setFillStyle(this.btnactive ? COLOR_ACTIVE : COLOR_HOVER);
    }

    down = () => {
        this.background.setFillStyle(COLOR_DOWN);
    }

    setActive = (btnactive) => {
        this.btnactive = btnactive;
        this.reset();
    }

    click = () => {
        if (this.sfx) this.sfx.play({ volume: .1 });
        if (this.handler) this.handler();
        this.hover();
    }

    setText = (text) => {
        this.text.setText(text);
        this.background.setSize(this.text.width + 2 * this.margin, this.text.height + 2 * this.margin);
        this.background.input.hitArea.setTo(this.background.x, this.background.y, this.background.width, this.background.height);
        this.setSize(this.background.width, this.background.height);
    }
}