const STATES = [
    {
        id: 'alone',
        message: 'Welcome to Artell! After a devastating virus outbreak, humanity has gone into hiding and relies on robots to provide supplies.\n\nStart by clicking on AL-1 and use a Move command.\n\n(Keyboard Shortcuts: W, A, S, or D)',
        advance: object => object.id === 'alone' && object.action?.id === 'move'
    },
    {
        id: 'tomato',
        message: 'Use your robots to scavenge for food, fuel, and raw materials. As you progress, you can set up farms for more reliable access to resources.\n\nMove AL-1 to the {icon:tomato} tomato and use the {icon:harvest} Harvest command.',
        advance: object => object.id === 'alone' && object.inventory?.tomato
    },
    {
        id: 'home',
        message: 'Deliver everything to your household. In return, they may provide useful supplies or get rid of some junk.\n\nMove AL-1 next to the Powers home and use the {icon:give} Give command to deliver the {icon:tomato} tomato.',
        advance: object => object.id === 'home' && object.depletions > 0
    },
    {
        id: 'alone',
        message: 'Looks like they left something for you. Make sure you take good care of it!\n\nWhile still next to the Powers home, use the {icon:take} Take command to grab the {icon:lore-pet-rock} pet rock.',
        advance: object => object.id === 'alone' && object.inventory?.['lore-pet-rock']
    },
    {
        id: 'home',
        message: 'Keep bringing supplies that your household needs. Click on the Powers home to see what\'s next.\n\nThey need some {icon:wood} wood for their fireplace. Look for a tree and use the {icon:harvest} Harvest command, then {icon:give} deliver it to your home.',
        advance: object => object.id === 'home' && object.depletions > 1
    },
    {
        id: 'home',
        message: 'Your household needs some coal for their stove. Look for some coal and use the {icon:dig} Dig command, then {icon:give} deliver it to your home.',
        advance: object => object.id === 'home' && object.depletions > 2
    },
    {
        id: 'alone',
        message: 'Good neighbors help each other in times of need. The Joyles have had a hard time finding food, so let\'s bring them some. In exchange for your help, neighbors will usually let you use their robots to help gather resources for the community.\n\nClick and drag the map to look around. Move east and {icon:give} Give {icon:lettuce} lettuce to the Joyles house.',
        advance: object => object.id === 'benders' && object.depletions > 0
    },
    {
        id: 'alone',
        message: 'The Joyles are thankful but that wasn\'t very filling. Tomatoes are better but you don\'t have many of them. You can {icon:plant} Plant some {icon:tomato-seed} tomato seeds and wait for them to grow. Since crops take time to fully mature, you might want to gather wheat, wood, and stone while you wait.\n\nBring the Joyles a few {icon:tomato} tomatoes once they\'re ready.',
        advance: object => object.id === 'benders' && object.depletions > 1
    },
    {
        id: 'alone',
        message: 'Now it\'s time for some real food. Making {icon:bread} bread takes a few steps, you will need to {icon:build} Build a {icon:mill} mill to make the {icon:flour} flour, and a {icon:campfire} campfire to cook with. Tooltips will show you the recipes.\n\nOnce you\'ve learned how to make bread, {icon:give} deliver some to the Joyles.',
        advance: object => object.id === 'benders' && object.depletions > 2
    },
    {
        id: 'alone',
        message: `You now know the basics of Artell! Continue finding houses and providing them with supplies, all while hunting for a cure that will save humanity!

Hints:
- Program your robots to have them automaticaly perform simple tasks.
- Use multiple robots to build bigger and better farms.
- It is important to multitask while waiting for crops and tasks.`,
        advance: object => false
    }
];

export default class Tutorial {
    constructor(engine) {
        this.engine = engine;
        this.engine.on('load', this.onLoad);
        this.engine.on('update', this.onUpdate);
    }

    onLoad = () => {
        this.annotate();
    }

    onUpdate = (object) => {
        const state = STATES[this.engine.state.tutorial];
        if (state?.advance(object)) this.advance();
    }

    advance = () => {
        this.engine.state.tutorial++;

        // update objects asynchronously
        window.setTimeout(this.annotate, 1);
    }

    annotate = () => {
        const previous = STATES[this.engine.state.tutorial - 1];
        if (previous) {
            try {
                this.engine.updateObject(previous.id, { highlight: undefined });
            } catch(e) {
                // object removed
            }
        }

        const next = STATES[this.engine.state.tutorial];
        if (next) {
            this.engine.updateObject(next.id, { highlight: true });
        }

        this.engine.listeners.tutorial.forEach(listener => listener(next?.message));
    }

    close = () => {
        const current = STATES[this.engine.state.tutorial];
        if (current) {
            this.engine.updateObject(current.id, { highlight: undefined });
        }

        this.engine.listeners.tutorial.forEach(listener => listener(undefined));
    }
}