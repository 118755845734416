import OriginContainer from './OriginContainer';
import Icon from './Icon';

import { engine, MARGIN } from './global';

const COLS = 5;

export default class RecipeBook extends OriginContainer {
    constructor(scene) {
        super(scene);

        const items = engine.recipes.outputs();

        for (let y = 0; y < items.length; y++) {
            for (let x = 0; x < COLS; x++) {
                const item = items[COLS * y + x];
                if (!item) break;

                const icon = new Icon(this.scene, item, null, true);
                icon.setPosition(MARGIN + x * 96, MARGIN + y * 64);
                this.container.add(icon);
            }
        }

        const width = Math.max(...this.container.list.map(o => o.x + o.width));
        const height = Math.max(...this.container.list.map(o => o.y + o.height));

        this.background = this.scene.add.rectangle(0, 0, width + MARGIN, height + MARGIN, 0x333333);
        this.background.setOrigin(0, 0);
        this.background.setStrokeStyle(1, 0xffffff, 1);
        this.container.addAt(this.background, 0);

        this.setSize(this.background.width, this.background.height);
    }
}