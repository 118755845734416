export default class Recipes {
    constructor(engine) {
        this.engine = engine;
        this.parse();
    }

    parse = () => {
        // pull recipe info from object data
        this.recipes = [];

        for (let key of this.engine.getObjectTypes()) {
            const object = this.engine.getObjectType(key);

            // check for buildings
            if (object.target?.build) {
                // convert into recipe-friendly format
                this.recipes.push({
                    input: object.target.build.input,
                    output: { [key]: 1 },
                    time: object.target.build.time
                });
            }

            // check for crafted items
            if (object.actions) {
                for (let action of Object.values(object.actions)) {
                    if (!Array.isArray(action)) continue;
                    for (let recipe of action) {
                        if (!recipe.input) continue; // TODO Show something like "Gathered" or "Grown"
                        if (recipe.output) {
                            // convert into recipe-friendly format
                            this.recipes.push({
                                input: recipe.input,
                                with: key,
                                output: recipe.output,
                                time: recipe.time
                            });
                        }
                    }
                }
            }
        }
    }

    all = () => {
        return this.recipes;
    }

    for = (item) => {
        return this.recipes.filter(recipe => recipe.output[item]);
    }

    with = (building) => {
        return this.recipes.filter(recipe => recipe.with === building);
    }

    outputs = () => {
        const out = new Set();
        for (let recipe of this.recipes) {
            for (let key of Object.keys(recipe.output)) {
                out.add(key);
            }
        }
        return Array.from(out);
    }
}