import OriginContainer from './OriginContainer';
import Icon from './Icon';

import { engine, MARGIN } from './global';

export default class RecipePicker extends OriginContainer {
    constructor(scene, x, y) {
        super(scene, x, y);

        this.label = this.scene.add.text(MARGIN, MARGIN, 'Choose a Recipe', { font: '12pt Calibri', fill: '#ffffff' });
        this.label.setStroke(0x000000, 3);
        this.container.add(this.label);

        // reuse icons
        this.icons = {};
    }

    update = (object) => {
        this.selected = object;
        Object.values(this.icons).forEach(icon => icon.visible = false);

        let x = MARGIN;
        let y = this.label.y + this.label.height;
        for (let recipe of engine.recipes.with(object.type)) {
            const item = Object.keys(recipe.output)[0];
            let icon = this.icons[item];

            if (!icon) {
                this.icons[item] = icon = new Icon(this.scene, item);
                icon.on('pointerup', () => this.onRecipe({ id: 'craft', item }));
                this.container.add(icon);
            }

            icon.visible = true;
            icon.setPosition(x, y);
            icon.setSelected(item === object.program?.code?.[0]?.item);

            x += icon.width;
            this.iconHeight = icon.height;
        }

        this.setSize(x, y + (this.iconHeight || 0));
    }

    onRecipe = (action) => {
        engine.updateObject(this.selected.id, { step: undefined, action: undefined, program: { language: 'recipepicker', code: [ action ] } });
    }
}