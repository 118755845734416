import Collision from './Collision';
import Terrain from './Terrain';

export default class State {
    constructor() {
        // save file
        this.name = 'New World';

        this.sumtime = 0;
        this.resumetime = Date.now();
        this.paused = false;

        this.id = null;
        this.seed = null;
        this.objects = {};
        this.programs = {};
        this.removed = [];

        this.viewport = { x: 0, y: 0, zoom: 1 };

        // transient data
        this.collision = new Collision();
        this.terrain = new Terrain();
        this.timeouts = {};

        // TODO Make setTimeouts work in background (worker threads? music? auto-pause?)
        // TODO Correct timeout innacuraccies by using last action's finish time as new actions start time
    }

    now = () => {
        return this.sumtime + Date.now() - this.resumetime;
    }

    pause = () => {
        if (this.paused) return;

        this.paused = true;
        this.sumtime += (Date.now() - this.resumetime);
    }

    resume = () => {
        if (!this.paused) return;

        this.paused = false;
        this.resumetime = Date.now();
    }

    schedule = (id, fn, delay) => {
        // TODO Need to handle pause/resume, probably shouldn't rely on window.setTimeout
        window.clearTimeout(this.timeouts[id]);
        this.timeouts[id] = window.setTimeout(fn, delay);
    }

    destroy = () => {
        Object.values(this.timeouts).forEach((id) => window.clearTimeout(id));
    }
}