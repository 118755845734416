import OriginContainer from './OriginContainer';
import Icon from './Icon';

import { engine } from './global';

const ICONMARGIN = 4;
const ICONHEIGHT = 32;
const ICONWIDTH = 32;

export default class ActionPanel extends OriginContainer {
    constructor(scene, x, y) {
        super(scene, x, y);

        // reuse buttons
        this.buttons = {};
        this.amount = 1;
    }

    update = (object, mode) => {
        if (mode !== this.mode) this.flyout = null; // close menus on switch
        Object.values(this.buttons).forEach(b => b.setVisible(false));

        const type = engine.getObjectType(object.type);
        this.selected = object;
        this.mode = mode;

        let currentX = 0;
        let currentY = 0;
        const next = () => {
            currentX += ICONWIDTH + ICONMARGIN;

            if (currentX > 450) {
                currentX = 0;
                currentY += ICONHEIGHT + ICONMARGIN;
            }
        }

        // flyout label
        if (!this.label) {
            this.label = this.scene.add.text(0, 0, "Flyout", { font: '12pt Calibri', fill: '#ffffff' });
            this.label.setStroke(0x000000, 3);
            this.container.add(this.label);
        }
        this.label.setVisible(this.flyout);
        if (this.flyout) currentY += this.label.height;

        // build
        if (this.flyout === 'build') {
            this.label.setText("Choose what to build:");

            const buildings = engine.getObjectTypes().filter(key => engine.getObjectType(key)?.target?.build);
            const available = new Set(buildings.filter(key => !engine.actions.helpers.remove(object.inventory, engine.getObjectType(key).target.build.input).error));

            for (let key of buildings) {
                const options = {
                    flyout: 'build',
                    unavailable: mode === 'manual' && !available.has(key)
                };
                this.makeButton(currentX, currentY, { id: 'build', type: key }, options);
                next();
            }

            currentX = 0;
            currentY += 1.5 * ICONHEIGHT + ICONMARGIN;
        }

        // plant
        if (this.flyout === 'plant') {
            this.label.setText("Choose what to plant:");

            const plants = engine.getObjectTypes().filter(key => engine.getObjectType(key)?.target?.plant);
            const available = new Set(plants.filter(key => !engine.actions.helpers.remove(object.inventory, engine.getObjectType(key).target.plant.input).error));

            for (let key of plants) {
                const options = {
                    flyout: 'plant',
                    unavailable: mode === 'manual' && !available.has(key)
                };
                this.makeButton(currentX, currentY, { id: 'plant', type: key }, options);
                next();
            }

            currentX = 0;
            currentY += 1.5 * ICONHEIGHT + ICONMARGIN;
        }

        // give
        if (this.flyout === 'give') {
            this.label.setText("Choose item to give:");

            const gives = engine.getItemTypes();
            const available = new Set(gives.filter(key => object.inventory?.[key]));

            for (let key of gives) {
                const options = {
                    flyout: 'give',
                    unavailable: mode === 'manual' && !available.has(key)
                };
                this.makeButton(currentX, currentY, { id: 'give', item: key, amount: this.amount }, options);
                next();
            }

            currentX = 0;
            currentY += 1.5 * ICONHEIGHT + ICONMARGIN;
        }

        // take
        if (this.flyout === 'take') {
            this.label.setText("Choose item to take:");

            const takes = engine.getItemTypes();
            const facing = engine.actions.helpers.facing(object.x, object.y, object.face, 1, 1, type.height, type.width);
            const from = engine.getObjectAt(facing.x, facing.y);
            const available = new Set(takes.filter(key => from?.inventory?.[key]));

            for (let key of takes) {
                const options = {
                    flyout: 'take',
                    unavailable: mode === 'manual' && !available.has(key)
                };
                this.makeButton(currentX, currentY, { id: 'take', item: key, amount: this.amount }, options);
                next();
            }

            currentX = 0;
            currentY += 1.5 * ICONHEIGHT + ICONMARGIN;
        }

        // install
        if (this.flyout === 'install') {
            this.label.setText("Choose upgrade to install:");

            const upgrades = engine.getItemTypes().filter(key => engine.getItemType(key).upgrade);
            const available = new Set(upgrades.filter(key => object.inventory?.[key]));

            for (let key of upgrades) {
                const options = {
                    flyout: 'install',
                    unavailable: mode === 'manual' && !available.has(key)
                };
                this.makeButton(currentX, currentY, { id: 'install', item: key }, options);
                next();
            }

            currentX = 0;
            currentY += 1.5 * ICONHEIGHT + ICONMARGIN;
        }

        // uninstall
        if (this.flyout === 'uninstall') {
            this.label.setText("Choose upgrade to uninstall:");

            const upgrades = engine.getItemTypes().filter(key => engine.getItemType(key).upgrade);
            const available = new Set(upgrades.filter(key => object.upgrades?.[key]));

            for (let key of upgrades) {
                const options = {
                    flyout: 'uninstall',
                    unavailable: mode === 'manual' && !available.has(key)
                };
                this.makeButton(currentX, currentY, { id: 'uninstall', item: key }, options);
                next();
            }

            currentX = 0;
            currentY += 1.5 * ICONHEIGHT + ICONMARGIN;
        }

        // move
        if (type?.actions?.move) {
            this.makeButton(currentX, currentY + 16, { id: 'move', direction: 'west' });
            this.makeButton(currentX + 32, currentY, { id: 'move', direction: 'north' });
            this.makeButton(currentX + 32, currentY + 32, { id: 'move', direction: 'south' });
            this.makeButton(currentX + 64, currentY + 16, { id: 'move', direction: 'east' });
            currentX += 3 * ICONWIDTH + ICONMARGIN;
        }

        // standard actions
        if (type?.actions?.dig) {
            this.makeButton(currentX, currentY + 16, { id: 'dig' });
            currentX += ICONWIDTH + ICONMARGIN;
        }
        if (type?.actions?.harvest) {
            this.makeButton(currentX, currentY + 16, { id: 'harvest' });
            currentX += ICONWIDTH + ICONMARGIN;
        }
        if (type?.actions?.destroy) {
            this.makeButton(currentX, currentY + 16, { id: 'destroy' });
            currentX += ICONWIDTH + ICONMARGIN;
        }
        if (type?.actions?.build) {
            this.makeButton(currentX, currentY + 16, { id: 'build', flyout: true });
            currentX += ICONWIDTH + ICONMARGIN;
        }
        if (type?.actions?.plant) {
            this.makeButton(currentX, currentY + 16, { id: 'plant', flyout: true });
            currentX += ICONWIDTH + ICONMARGIN;
        }
        if (type?.actions?.give) {
            this.makeButton(currentX, currentY + 16, { id: 'give', flyout: true });
            currentX += ICONWIDTH + ICONMARGIN;
        }
        if (type?.actions?.take) {
            this.makeButton(currentX, currentY + 16, { id: 'take', flyout: true });
            currentX += ICONWIDTH + ICONMARGIN;
        }
        if (type?.actions?.install) {
            this.makeButton(currentX, currentY + 16, { id: 'install', flyout: true });
            currentX += ICONWIDTH + ICONMARGIN;
        }
        if (type?.actions?.uninstall) {
            this.makeButton(currentX, currentY + 16, { id: 'uninstall', flyout: true });
            currentX += ICONWIDTH + ICONMARGIN;
        }

        // wait
        if (type?.actions?.wait && mode !== 'manual') {
            this.makeButton(currentX, currentY + 16, { id: 'wait', amount: 1000 * this.amount });
            currentX += ICONWIDTH + ICONMARGIN;
        }

        // turn
        if (type?.actions?.turn) {
            this.makeButton(currentX, currentY + 16, { id: 'turn', direction: 'west' });
            this.makeButton(currentX + 32, currentY, { id: 'turn', direction: 'north' });
            this.makeButton(currentX + 32, currentY + 32, { id: 'turn', direction: 'south' });
            this.makeButton(currentX + 64, currentY + 16, { id: 'turn', direction: 'east' });
            currentX += 3 * ICONWIDTH + ICONMARGIN;
        }

        currentY += 2 * ICONHEIGHT + ICONMARGIN;

        this.setSize(currentX, currentY);
    }

    makeButton = (x, y, action, options) => {
        // figure out icon to use
        let img = action.id + (action.direction ? '-' + action.direction : '');
        let id = action.id + action.direction + action.item + action.type;
        if (action.item) {
            img = action.item;
        } else if (action.type) {
            img = action.type;
        }

        // create the button
        let icon = this.buttons[id];
        if (!icon) {
            this.buttons[id] = icon = new Icon(this.scene, img);
            icon.on('pointerup', () => this.onAction(icon.action));
            icon.on('wheel', (pointer, deltaX, deltaY, deltaZ) => this.setAmount(this.amount + (deltaY > 0 ? -1 : 1)));

            this.container.add(icon);
        }

        icon.action = action; // for event handler
        icon.setAlpha(options?.unavailable ? .5 : 1);
        icon.setVisible(!options?.flyout || options?.flyout === this.flyout);
        icon.setQuantity(action.amount);
        icon.setPosition(x, y);
    }

    onAction = (action) => {
        if (action.flyout) {
            this.flyout = (this.flyout === action.id) ? null : action.id;

            // trick to trigger an update
            engine.updateObject(this.selected.id, {});
            return;
        }

        this.flyout = null; // close any open menus
        if (this.mode === 'manual') {
            // run immediately
            engine.updateObject(this.selected.id, { action });
        } else if (this.mode === 'code') {
            // add to bottom of list
            const prev = this.selected?.program?.code || [];
            const code = [ ...prev, action ];
            engine.updateObject(this.selected.id, { program: { language: 'actionlist', code } });
        }
    }

    setAmount = (amount) => {
        this.amount = Math.max(amount, 1);
        this.update(this.selected, this.mode);
    }
}