import Actions from './Actions';
import Program from './Program';
import Tutorial from './Tutorial';
import Recipes from './Recipes';
import State from './State';

import objects from './objects.json';
import items from './items.json';
import actions from './actions.json';

import map from '../generated/map.json';
import api from '../api/dfa';
import utils from './utils';

export default class Engine {
    constructor() {
        this.listeners = {
            unload: [],
            load: [],
            update: [],
            remove: [],
            tutorial: [],
            select: [], // UI support
            debug: [], // UI support
            viewport: [] // UI support
        };
        this.actions = new Actions(this);
        this.program = new Program(this);
        this.tutorial = new Tutorial(this);
        this.recipes = new Recipes(this);

        this.state = null;
    }

    now = () => {
        return this.state.now();
    }

    on = (event, listener) => {
        this.listeners[event].push(listener);
    }

    off = (event, listener) => {
        this.listeners[event] = this.listeners[event].filter(elem => elem !== listener);
    }

    save = async () => {
        // TODO show a progress bar to user, and warn of any errors
        if (!this.state) throw new Error('Cannot call save() without an active world');

        // would be nice to remove unchanged objects, but pretty much everything takes actions
        const json = {
            version: 1,
            id: this.state.id,
            name: this.state.name,
            savetime: Date.now(),
            gametime: this.state.now(),
            seed: this.state.seed,
            tutorial: this.state.tutorial,
            objects: this.state.objects,
            programs: this.state.programs,
            removed: this.state.removed,
            viewport: this.state.viewport
        };

        await api.save(json.id, json);
    }

    remove = async (id) => {
        await api.remove(id);
    }

    load = async (json) => {
        // TODO show a progress bar to user, and warn of any errors
        if (this.state) throw new Error('Cannot call load() with an active world');

        if (typeof json === 'string') {
            // load save file
            json = await api.load(json);
        }

        // merge base map objects
        const removed = new Set(json.removed);
        for (let object of Object.values(map.objects)) {
            const id = object.id;

            if (!id) throw new Error('A map object is missing an id. ' + JSON.stringify(object));
            if (removed.has(id)) continue;

            if (json.objects[id]) {
                // merge map with savefile
                const src = json.objects[id];
                const changed = src.changed || [];

                json.objects[id] = { ...object, changed };
                changed.forEach(key => json.objects[id][key] = src[key]);
            } else {
                // load directly from map
                json.objects[id] = object;
            }
        }

        // load JSON
        this.state = new State();
        this.state.id = json.id;
        this.state.name = json.name;
        this.state.seed = json.seed;
        this.state.sumtime = json.gametime;
        this.state.tutorial = json.tutorial;
        this.state.removed = json.removed;
        this.state.programs = json.programs || {};
        this.state.viewport = json.viewport || { x: 0, y: 0, zoom: 1 };

        // load map objects first, and then player objects can avoid collisions
        const objects = Object.values(json.objects);
        objects.sort((a, b) => (a.changed?.length || 0) - (b.changed?.length || 0));
        for (let object of objects) {
            this.setObject(object, { reposition: true });
        }

        this.listeners.load.forEach(listener => listener());
    }

    worlds = async () => {
        // TODO preload this or show progress bar
        const results = await api.list();
        results.sort((a, b) => b.savetime - a.savetime);
        return results;
    }

    new = async (name) => {
        if (this.state) throw new Error('Cannot call new() with an active world');
        if (!name) throw new Error('New worlds must have a name');

        const json = {
            version: 1,
            name,
            savetime: Date.now(),
            gametime: 0,
            seed: utils.uid(),
            tutorial: 0,
            objects: {},
            programs: {},
            removed: [],
            viewport: { x: 0, y: 0, zoom: 1 }
        };

        const saved = await api.save(null, json);
        await this.load(saved.id);
    }

    exit = () => {
        if (!this.state) throw new Error('Cannot call exit() without an active world');

        this.state.destroy();
        this.state = null;

        this.listeners.unload.forEach(listener => listener());
    }

    getObject = (id) => {
        return this.state.objects[id];
    }

    getObjectAt = (x, y) => {
        const id = this.state.collision.get(x, y);
        return this.getObject(id);
    }

    getObjectHome = (home) => {
        return Object.values(this.state.objects).filter(obj => obj.home === home);
    }

    // checks if a collision with non-id will happen
    collision = (x, y, width, height, id) => {
        return this.state.collision.check(x, y, width, height, id);
    }

    // checks if the terrain is buildable
    buildable = (x, y, width, height) => {
        for (let dx = x; dx < x + width; dx++) {
            for (let dy = y; dy < y + height; dy++) {
                const block = this.state.terrain.block(dx, dy);
                if (!block?.target?.build) return false;
            }
        }

        return true;
    }

    // finds a spot for collision/buildable
    reposition = (x, y, width, height) => {
        const range = 1000;

        // loop from 0 to max range, but alternate +/-
        for (let dx = 0; dx < range; dx = -dx + (dx <= 0 ? 1 : 0)) {
            for (let dy = 0; dy < range; dy = -dy + (dy <= 0 ? 1 : 0)) {
                if (this.collision(x + dx, y + dy, width, height)) continue; // no room
                if (!this.buildable(x + dx, y + dy, width, height)) continue; // bad terrain
                return { x: x + dx, y: y + dy};
            }
        }

        throw new Error('No free space');
    }

    action = (id) => {
        const object = this.state.objects[id];
        if (!object || !object.action) return; // object or action removed
        if (object.action.error) return; // don't keep running errors

        const action = this.actions[object.action.id];
        this.updateObject(id, action(object));
    }

    updateObject = (id, props) => {
        const object = this.state.objects[id];
        if (!object) throw new Error('Object [' + id + '] not found');

        const resetTimeout = !props.action && object.action;

        // don't cancel existing action when it is repeated
        if (props.action && object.action) {
            if (Object.keys(props.action).every(key => props.action[key] === object.action[key])) {
                delete props.action;
            }
        }

        if (map.objects[id]) {
            // track property changes
            const changed = [ ...(object.changed || []), ...Object.keys(props) ];
            props.changed = Array.from(new Set(changed));
        }

        this.setObject({ ...object, ...props });

        if (resetTimeout) {
            // object has changed, so immediately recheck any in-progress actions
            this.state.schedule('action-' + id, () => this.action(id), 0);
        }
    }

    setObject = (object, options) => {
        const type = this.getObjectType(object.type);

        // assign id to new objects
        if (!object.id) {
            object.id = utils.uid();
        }

        // set default program
        if (!object.program) {
            const defaultProgram = type.program;
            if (defaultProgram) {
                object.program = defaultProgram;
                object.power = true;
            }
        }

        // update stats
        // TODO try to detect when re-calculation is actually needed
        this.updateStats(object);

        // assert valid collision
        // TODO we should do terrain check here instead of in build, in case map changes
        if (this.state.collision.check(object.x, object.y, type.width, type.height, object.id)) {
            if (options?.reposition) {
                const reposition = this.reposition(object.x, object.y, type.width, type.height);
                object.x = reposition.x;
                object.y = reposition.y;
                // TODO alert user things had to move
            } else {
                throw new Error('Overlapping collision');
            }
        }

        // update collision
        const old = this.state.objects[object.id];
        if (!old || old.x !== object.x || old.y !== object.y) {
            if (old) this.state.collision.remove(old.x, old.y, type.width, type.height);
            this.state.collision.add(object.x, object.y, type.width, type.height, object.id);
        }

        // execute program (will select an action that is run next)
        if (object.power) {
            const program = this.program[object?.program?.language];
            if (program) program(object);
        }

        // invoke any action
        if (object.action && !object.ghost) {
            const id = object.id;
            this.state.schedule('action-' + id, () => this.action(id), (object.action.end || object.action.start || 0) - this.now());
        }

        // update state
        this.state.objects[object.id] = object;

        // call listeners
        this.listeners.update.forEach(listener => listener(object));

        return object;
    }

    removeObject = (object) => {
        // update collision
        const old = this.state.objects[object.id];
        const type = this.getObjectType(object.type);
        if (old) this.state.collision.remove(old.x, old.y, type.width, type.height);

        // update state
        delete this.state.objects[object.id];
        if (map.objects[object.id]) this.state.removed.push(object.id); // track removed map objects

        // call listeners
        this.listeners.remove.forEach(listener => listener(object));
    }

    updateStats = (object) => {
        const type = this.getObjectType(object.type);
        object.stats = type.stats ? { ...type.stats } : {};

        for (let key of Object.keys(object.upgrades || {})) {
            const item = items[key];
            for (let stat of Object.keys(item.stats || {})) {
                object.stats[stat] += item.stats[stat];
            }
        }
    }

    getPrograms = () => {
        return Object.values(this.state.programs);
    }

    getProgram = (id) => {
        return this.state.programs[id];
    }

    setProgram = (program) => {
        this.state.programs[program.id] = program;
    }

    getObjectTypes = () => {
        return Object.keys(objects);
    }

    getObjectType = (name) => {
        return objects[name];
    }

    getItemTypes = () => {
        return Object.keys(items);
    }

    getItemType = (name) => {
        return items[name];
    }

    getActions = () => {
        return Object.keys(actions);
    }

    getAction = (name) => {
        return actions[name];
    }

    // gets object/item/action depending on name
    getType = (name) => {
        // TODO Some objects and items have same name (e.g. wheat)
        return this.getObjectType(name) || this.getItemType(name) || this.getAction(name);
    }

    getViewport = () => {
        return this.state.viewport;
    }

    setViewport = (viewport) => {
        this.state.viewport = { ...this.state.viewport, ...viewport };
        this.listeners.viewport.forEach(listener => listener(this.state.viewport));
    }
}