import Button from './Button';
import OriginContainer from './OriginContainer';

import { engine } from './global';
import Progress from './Progress';

export default class CodeLine extends OriginContainer {
    constructor(scene, x, y) {
        super(scene, x, y);

        this.progress = new Progress(scene);
        this.container.add(this.progress);

        this.minus = new Button(scene, 0, 0, ' - ', { fontSize: 12, margin: 1 });
        this.minus.on('pointerup', this.removeLine);
        this.container.add(this.minus);

        this.up = new Button(scene, 0, 0, '↑', { fontSize: 12, margin: 1 });
        this.up.on('pointerup', this.moveUp);
        this.container.add(this.up);

        this.down = new Button(scene, 0, 0, '↓', { fontSize: 12, margin: 1 });
        this.down.on('pointerup', this.moveDown);
        this.container.add(this.down);

        this.current = new Button(scene, 0, 0, '>', { fontSize: 12, margin: 1 });
        this.current.on('pointerup', this.setCurrent);
        this.container.add(this.current);

        this.pick = new Button(scene, 0, 0, '>', { fontSize: 12, margin: 1 });
        this.pick.on('pointerup', this.pickLine);
        this.container.add(this.pick);

        this.text = this.scene.add.text(0, 0, '', { font: '12pt Calibri', fill: '#ffffff' });
        this.text.setStroke(0x000000, 3);
        this.container.add(this.text);

        this.setSize(this.text.width, Math.max(this.text.height, this.minus.height) + 5);
    }

    update = (object, mode, index) => {
        this.object = object; // for +/- buttons
        this.index = index;

        let x = 0;

        // buttons
        this.minus.setPosition(0, 0);
        this.minus.setVisible(mode === 'code');

        this.up.setPosition(this.minus.x + this.minus.width + 5, 0);
        this.up.setVisible(mode === 'code');

        this.down.setPosition(this.up.x + this.up.width + 5, 0);
        this.down.setVisible(mode === 'code');

        this.current.setPosition(this.down.x + this.down.width + 5, 0);
        this.current.setVisible(mode === 'code');
        this.current.setText((object?.step || 0) === index ? '>' : '  ');
        if (mode === 'code') x = this.current.x + this.current.width + 5;

        this.pick.setPosition(0, 0);
        this.pick.setVisible(mode === 'load');
        if (mode === 'load') x = this.pick.x + this.pick.width + 5;

        // text
        const action = object.program?.code?.[this.index] || { id: 'noop' };
        const params = [];
        Object.keys(action).forEach(key => {
            if (key !== 'id') params.push(key + '=' + action[key]);
        });
        this.text.setText(action.id + '(' + params.join(', ') + ')');
        this.text.setPosition(x, 0);

        if (object.power && object.action && this.index === object.step) {
            this.progress.maxWidth = 100;
            this.progress.setPosition(0, this.text.height + 6);
            this.progress.setProgress(object.action.start, object.action.end);
        } else {
            this.progress.clearProgress();
        }
    }

    moveUp = () => {
        if (this.index > 0) this.swap(this.index, this.index - 1);
    }

    moveDown = () => {
        const to = this.index + 1;
        if (to < this.object.program.code.length) this.swap(this.index, to);
    }

    swap = (i, j) => {
        const code = [ ...this.object.program.code ];
        code[i] = this.object.program.code[j];
        code[j] = this.object.program.code[i];
        engine.updateObject(this.object.id, { step: undefined, program: { language: 'actionlist', code } });
    }

    removeLine = () => {
        const code = [ ...this.object.program.code ];
        code.splice(this.index, 1);
        engine.updateObject(this.object.id, { step: undefined, program: { language: 'actionlist', code } });
    }

    pickLine = () => {
        const line = this.object.program.code[this.index];
        const program = engine.getProgram(line.id);
        engine.updateObject(this.object.id, { program: undefined }); // make sure a code change is detected
        engine.updateObject(this.object.id, { program });
    }

    setCurrent = () => {
        engine.updateObject(this.object.id, { step: this.index });
    }
}